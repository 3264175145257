import { UserConfiguration } from "../types/types";

interface CostApiConfiguration {
  Type: string;
  NumberOfSeats: number;
  Leg: string;
  ElectricalManagementDown: string;
  ElectricalManagementTop: string;
  ElectricalWireEntry: string;
  SideModesty: string;
  MainModesty: string;
  MainScreen: string;
  ReturnScreen: string;
  Length: number;
  Height: number;
  Accessories: string[];
  Pedestal: string;
}

export const getCostApiConfiguration = (
  userConfig: Partial<UserConfiguration>
): Partial<CostApiConfiguration> => {
  return {
    Type: userConfig.type,
    NumberOfSeats: userConfig.noOfSeats,
    Leg: userConfig.Legs,
    ElectricalManagementDown: userConfig["Raceway"],
    ElectricalManagementTop: userConfig["Wire Access"],
    ElectricalWireEntry: userConfig["Wire Entry"],
    SideModesty: userConfig["Side Modesty"],
    MainModesty: userConfig["Main Modesty"],
    MainScreen: userConfig["Main screen"],
    ReturnScreen: userConfig["Return screen"],
    Length: userConfig.Length,
    Height: userConfig.Height,
    Accessories: userConfig.Accessories,
    Pedestal: userConfig.Pedestal,
  };
};
