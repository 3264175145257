import { features } from "process";
import Condition from "yup/lib/Condition";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const sideTableLegRules = [
  // Colors
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Colors"] }],
    node: "leg",
    pid: "63032f315aa214900a4ba83e",
  },
  // Connect
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Connect"] }],
    node: "leg",
    pid: "63032f54057d9f316208ecb1",
  },
  // Collaborate
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Collaborate"] }],
    node: "leg",
    pid: "63032e9621d94b93ab6b285f",
  },
  // Synergy
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Synergy"] }],
    node: "leg",
    pid: "63032fc9748e752da58dc7ec",
  },
  // Perform
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Perform"] }],
    node: "leg",
    pid: "63032f77748e752da58dc7bd",
  },
  // Switch Angular
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Switch Angular"] }],
    node: "leg",
    pid: "63032f9a9c4df171f5b2f9dc",
  },
];

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Cabin Table",
            size: 1,
            thumbnail: "assets/system/thumbnails/cabin/base.png",
            productId: "668253435c08a09a67a8eac4",
            tableTop: ["table_top"],
            disableFeatures: [
              "Color",
              "Back",
              "Return Table Top Leg",
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
              "Pedestal's Legs",
              "Back Dimension Options",
              "Return Dimension Options",
              "Return Dimension Option",
              "Return Dimensions Option",
            ],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            enableOptions: ["Europa", "Styl"],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
                rules: [
                  {
                    type: hide,
                    node: "Eco2",
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco",
                  },
                  {
                    type: show,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco2",
                  },
                ],
              },
              {
                name: "700 mm",
                value: 700,
                rules: [
                  {
                    type: hide,
                    node: "Eco2",
                  },
                  {
                    type: hide,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco",
                  },
                  {
                    type: show,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco2",
                  },
                ],
              },
              {
                name: "750 mm",
                value: 750,
                rules: [
                  {
                    type: hide,
                    node: "Eco2",
                  },
                  {
                    type: hide,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco",
                  },
                  {
                    type: show,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco2",
                  },
                ],
              },
              {
                name: "900 mm",
                value: 900,
                rules: [
                  {
                    type: show,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco",
                  },
                  {
                    type: hide,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco2",
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Metal"],
                      },
                    ],
                    node: "metalmodestymid",
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Metal"],
                      },
                    ],
                    node: [
                      "metalmodestymidEco2,prelammodestymid",
                      "prelammodestymidEco2",
                    ],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Prelam"],
                      },
                    ],
                    node: "prelammodestymid",
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Prelam"],
                      },
                    ],
                    node: [
                      "metalmodestymid",
                      "metalmodestymidEco2",
                      "prelammodestymidEco2",
                    ],
                  },
                ],
              },
              {
                name: "1050 mm",
                value: 1050,
                rules: [
                  {
                    type: show,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco",
                  },
                  {
                    type: hide,

                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                    ],

                    node: "Eco2",
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Metal"],
                      },
                    ],
                    node: "metalmodestymid",
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Metal"],
                      },
                    ],
                    node: [
                      "metalmodestymidEco2,prelammodestymid",
                      "prelammodestymidEco2",
                    ],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Prelam"],
                      },
                    ],
                    node: "prelammodestymid",
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Side Modesty",
                        values: ["Prelam"],
                      },
                    ],
                    node: [
                      "metalmodestymid",
                      "metalmodestymidEco2",
                      "prelammodestymidEco2",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Storages",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Return",
            required: false,

            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Back",
                  "Return Table Top Leg",
                  "Return Dimension Options",
                  "Return Dimension Option",
                  "Return Dimensions Option",
                ],
                enableFeatures: ["Legs"],
                rules: [
                  {
                    type: hide,

                    node: ["return"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Eco"] }],
                    node: "Eco",
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Io"] }],
                    node: ["io"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["@Work"] }],
                    node: ["@work"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Europa"] }],
                    node: ["Europa"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Scope"] }],
                    node: ["scope"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Styl"] }],
                    node: ["styl"],
                  },
                ],
              },
              {
                name: "Return Table Top",
                "Table Top Options": "Plain",
                thumbnail: "assets/system/thumbnails/cabin/returntabletop.png",
                Length: 600,
                Depth: 450,
                enableFeatures: [
                  "Back",
                  "Return Table Top Leg",
                  "Return Dimension Options",
                ],
                disableFeatures: [
                  "Legs",
                  "Return Dimension Option",
                  "Return Dimensions Option",
                ],
                rules: [
                  {
                    type: replacePart,
                    node: ["return"],
                    pid: "https://content.helloviewer.io/uploads/02433f8e-5072-4cac-bd62-016b2a6443e6/1_6_2024/1719817288121/return2.glb",
                  },
                  {
                    type: show,
                    node: ["return"],
                  },
                  {
                    type: hide,
                    node: [
                      "scope",
                      "Eco",
                      "Europa",
                      "@work",
                      "styl",
                      "io",
                      "Eco2return",
                      "Ecoreturn",
                      "ioreturn",
                      "scopereturn",
                    ],
                  },
                  {
                    type: hide,
                    node: ["Eco2return", "ioreturn", "scopereturn"],
                  },
                ],
              },
              {
                name: "Return Storage",
                thumbnail: "assets/system/thumbnails/cabin/reutrnstorage.png",
                enableFeatures: ["Back", "Legs", "Return Dimension Option"],
                disableFeatures: [
                  "Return Table Top Leg",
                  "Return Dimension Options",
                  "Return Dimensions Option",
                ],
                rules: [
                  {
                    type: replacePart,
                    node: ["return"],
                    pid: "https://content.helloviewer.io/uploads/a029d16f-f148-445e-953a-099d7166aa4c/1_6_2024/1719841365046/return2.glb",
                  },
                  {
                    type: show,
                    node: ["return"],
                  },

                  {
                    type: hide,
                    node: [
                      "c_handle",
                      "D_handle",
                      "storage_door_FLUSH",
                      "LOCK",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Eco"] }],
                    node: "Eco",
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Io"] }],
                    node: ["io"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["@Work"] }],
                    node: ["@work"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Europa"] }],
                    node: ["Europa"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Scope"] }],
                    node: ["scope"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Styl"] }],
                    node: ["styl"],
                  },
                ],
              },
              {
                name: "Return Storage 2",
                thumbnail: "assets/system/thumbnails/cabin/reutrnstorage.png",
                enableFeatures: ["Back", "Legs", "Return Dimensions Option"],
                disableFeatures: [
                  "Return Table Top Leg",
                  "Return Dimension Options",
                  "Return Dimension Option",
                ],
                rules: [
                  {
                    type: replacePart,
                    node: ["return"],
                    pid: "https://content.helloviewer.io/uploads/7ccdb2b5-a543-4202-856e-ed790d93635c/1_6_2024/1719841287065/returnstorage3.glb",
                  },
                  {
                    type: show,
                    node: ["return"],
                  },
                  {
                    type: hide,
                    node: [
                      "c_handle",
                      "D_handle",
                      "storage_door_FLUSH",
                      "LOCK",
                      "storage_drawer_FLUSH",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Eco"] }],
                    node: "Eco",
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Io"] }],
                    node: ["io"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["@Work"] }],
                    node: ["@work"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Europa"] }],
                    node: ["Europa"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Scope"] }],
                    node: ["scope"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Legs", values: ["Styl"] }],
                    node: ["styl"],
                  },
                ],
              },
            ],
          },
          {
            name: "Return Table Top Leg",
            required: false,
            options: [
              {
                name: "Return Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",

                rules: [
                  {
                    type: hide,
                    condition: [
                      { feature: "Return", values: "Return Table Top" },
                    ],
                    node: [
                      "scope",
                      "Eco",
                      "Europa",
                      "@work",
                      "styl",
                      "io",
                      "Eco2return",
                      "Ecoreturn",
                      "ioreturn",
                      "scopereturn",
                    ],
                  },
                  {
                    type: show,
                    node: ["Ecoreturn"],
                  },
                ],
              },
              {
                name: "Return IO",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",

                rules: [
                  {
                    type: hide,
                    condition: [
                      { feature: "Return", values: "Return Table Top" },
                    ],
                    node: [
                      "scope",
                      "Eco",
                      "Europa",
                      "@work",
                      "styl",
                      "io",
                      "Eco2return",
                      "Ecoreturn",
                      "ioreturn",
                      "scopereturn",
                    ],
                  },
                  {
                    type: show,
                    node: ["ioreturn"],
                  },
                ],
              },
              {
                name: "Return Scope",

                thumbnail: "assets/system/thumbnails/Parts/Eco.png",

                rules: [
                  {
                    type: hide,
                    condition: [
                      { feature: "Return", values: "Return Table Top" },
                    ],
                    node: [
                      "scope",
                      "Eco",
                      "Europa",
                      "@work",
                      "styl",
                      "io",
                      "Eco2return",
                      "Ecoreturn",
                      "ioreturn",
                      "scopereturn",
                    ],
                  },
                  {
                    type: show,
                    node: ["scopereturn"],
                  },
                ],
              },
            ],
          },
          {
            name: "Return Dimension Options",
            required: false,
            options: [
              {
                name: "750L X 450D X 750H",
                
              },
              {
                name: "900L X 450D X 750H",
                
              },
              {
                name: "1050L X 450D X 750H",
                
              },
              {
                name: "1200L X 450D X 750H",
                
              },
              {
                name: "1350L X 450D X 750H",
                
              },
            ],
          },
          {
            name: "Return Dimension Option",
            required: false,
            options: [
              {
                name: "750L X 450D X 750",
                
              },
              {
                name: "900L X 450D X 750",
                
              },
              {
                name: "1050L X 450D X 750",
                
              },
              {
                name: "1200L X 450D X 750",
                
              },
              {
                name: "1350L X 450D X 750",
                
              },
            ],
          },
          {
            name: "Return Dimensions Option",
            required: false,
            options: [
              {
                name: "750L X 450 X 750H",
                
              },
              {
                name: "900L X 450 X 750H",
                
              },
              {
                name: "1050L X 450 X 750H",
                
              },
              {
                name: "1200L X 450 X 750H",
                
              },
              {
                name: "1350L X 450 X 750H",
                
              },
            ],
          },
          {
            name: "Back",
            required: false,

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Back Dimension Options"],
                rules: [
                  {
                    type: hide,
                    node: ["back"],
                  },
                ],
              },
              {
                name: "Back Storage 1",
                thumbnail: "assets/system/thumbnails/cabin/backstorage.png",
                enableFeatures: ["Back Dimension Options"],
                rules: [
                  {
                    type: replacePart,
                    node: ["back"],
                    pid: "https://content.helloviewer.io/uploads/cefdcb64-f186-4830-a65f-cc29de06ecab/1_6_2024/1719841317442/back1.glb",
                  },
                  {
                    type: hide,
                    node: ["back"],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Return",
                        values: [
                          "Return Table Top",
                          "Return Storage",
                          "Return Storage 2",
                        ],
                      },
                    ],

                    node: ["back"],
                  },
                  {
                    type: hide,
                    node: [
                      "c_handleback",
                      "D_handleback",
                      "Back_storage_door_FLUSH",
                      "LOCKback",
                    ],
                  },
                  // {
                  //   type: repla,
                  //   node: ["", "", "", "", ""],
                  // },
                ],
              },
            ],
          },
          {
            name: "Back Dimension Options",
            required: false,
            options: [
              {
                name: "1200L X 450D X 750H",
                
              },
              {
                name: "1350L X 450D X 750H",
                
              },
              {
                name: "1500L X 450D X 750H",
                
              },
              {
                name: "1650L X 450D X 750H",
                
              },
              {
                name: "1800L X 450D X 750H",
                
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,
            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/cabin/base.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_waterfall", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "Table_top_waterfall",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment@work_waterfall",
                      "mid_leg_gorment_waterfall",
                      "gorment2@work_waterfall",
                      "Table_top_radious",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment@work_radious",
                      "mid_leg_gorment_radious",
                      "gorment2@work_radious",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/cabin/base.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_waterfall"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",

                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment@work_waterfall",
                      "mid_leg_gorment_waterfall",
                      "gorment2@work_waterfall",
                      "Table_top_radious",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment@work_radious",
                      "mid_leg_gorment_radious",
                      "gorment2@work_radious",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/cabin/base.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_waterfall", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment@work",
                      "mid_leg_gorment",
                      "gorment2@work",
                      "Table_top_waterfall",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment@work_waterfall",
                      "mid_leg_gorment_waterfall",
                      "gorment2@work_waterfall",

                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment@work_radious",
                      "mid_leg_gorment_radious",
                      "gorment2@work_radious",
                    ],
                  },
                ],
              },
            ],
          },
          // {
          //   name: "Plain Raceway Options",
          //   required: false,
          //   thumbnail: "/assets/components/ColorsSharing.png",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["Flipup", "gorment", "Raceway"],
          //     },
          //   ],
          //   options: [
          //     {
          //       name: "Flip Up",
          //       thumbnail: "assets/system/thumbnails/Parts/flipup.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       default: true,
          //       enableOptions: ["Mid Prelam", "Mid Metal"],

          //       rules: [
          //         {
          //           type: show,

          //           node: ["Flipup", "connecter", "mid_leg"],
          //         },
          //         {
          //           type: hide,

          //           node: [
          //             "Table_top",
          //             "gorment2",
          //             "gorment",
          //             "gorment3",
          //             "gorment@work",
          //             "mid_leg_gorment",
          //             "gorment2@work",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["Eco", "Noah", "Scope"],
          //             },
          //           ],
          //           node: [
          //             "Raceway",
          //             "gorment",
          //             "gorment2",
          //             "gorment3",

          //             "gorment@work",
          //             "gorment2@work",
          //           ],
          //         },

          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["Flipup", "gorment2@work", "connecter"],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["Flipup@work"],
          //         },
          //       ],
          //     },

          //     {
          //       name: "Gromet with vertical raceway",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],

          //       disableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup",
          //             "Raceway",
          //             "gorment2",
          //             "gorment3",

          //             "Flipup@work",
          //             "connecter",
          //             "mid_leg",
          //           ],
          //         },
          //         {
          //           type: show,
          //           node: ["gorment", "Table_top", "mid_leg_gorment"],
          //         },

          //         {
          //           type: hide,
          //           node: [
          //             "mid_leg",
          //             "prelammid",
          //             "prelammid@work",
          //             "metalmodestymid",
          //             "metalmodestymid@work",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment", "gorment2@work"],
          //         },
          //         {
          //           type: applyMaterial,
          //           node: ["GORMET"],
          //           condition: [
          //             {
          //               feature: "Laminate",
          //               values: [
          //                 "Mangfall Beech",
          //                 "Acacia",
          //                 "Urban Teak",
          //                 "SILVER GREY DIFFUSE",
          //                 "Cairo Walnut BaseColor",
          //                 "Maldau Acacia BaseColor",
          //                 "plain DefaultMaterial BaseColor",
          //                 "Thansau Maple BaseColor",
          //               ],
          //             },
          //           ],
          //           textureUrl:
          //             "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
          //         },

          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment@work"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Gromet With faceplate Cutting",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup",
          //             "Raceway",
          //             "gorment",
          //             "gorment2",
          //             "gorment3",
          //             "Flipup@work",
          //             "gorment@work",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           node: ["gorment2", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2",
          //             "Flipup@work",
          //             "gorment@work",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment2@work"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Gromet",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup",
          //             "Raceway",
          //             "gorment",
          //             "gorment2",
          //             "Flipup@work",
          //             "gorment@work",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           node: ["gorment3", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2",
          //             "Flipup@work",
          //             "gorment@work",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment3@work"],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Reverse Waterfall Raceway Options",
          //   required: false,
          //   thumbnail: "/assets/components/ColorsSharing.png",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["Flipup", "gorment", "Raceway"],
          //     },
          //   ],
          //   options: [
          //     {
          //       name: "Flip Up",
          //       thumbnail: "assets/system/thumbnails/Parts/flipup.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       default: true,
          //       enableOptions: ["Mid Prelam", "Mid Metal"],

          //       rules: [
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["Flipup_waterfall", "connecter", "mid_leg"],
          //         },
          //         {
          //           type: hide,

          //           node: [
          //             "Table_top",
          //             "gorment2",
          //             "gorment",
          //             "gorment@work",
          //             "mid_leg_gorment",
          //             "gorment2@work",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["Eco", "Noah", "Scope"],
          //             },
          //           ],
          //           node: [
          //             "Raceway",
          //             "gorment",
          //             "gorment2",
          //             "gorment3",

          //             "gorment@work",
          //             "gorment2@work",
          //           ],
          //         },

          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["Flipup", "gorment2@work", "connecter"],
          //         },
          //         {
          //           type: show,
          //           ////
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["Flipup@work_waterfall"],
          //         },
          //       ],
          //     },

          //     {
          //       name: "Gromet with vertical raceway",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],

          //       disableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_waterfall",
          //             "Raceway",
          //             "gorment2_waterfall",
          //             "gorment3_waterfall",

          //             "Flipup@work_waterfall",
          //             "connecter",
          //             "mid_leg",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: [
          //             "gorment_waterfall",
          //             "Table_top_waterfall",
          //             "mid_leg_gorment",
          //           ],
          //         },

          //         {
          //           type: hide,
          //           node: [
          //             "mid_leg",
          //             "prelammid",
          //             "prelammid@work",
          //             "metalmodestymid",
          //             "metalmodestymid@work",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment_waterfall", "gorment2@work_waterfall"],
          //         },

          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],

          //           node: ["gorment@work_waterfall"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Gromet With faceplate Cutting",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_waterfall",
          //             "Raceway",
          //             "gorment_waterfall",
          //             "gorment2_waterfall",
          //             "gorment3_waterfall",
          //             "Flipup@work_waterfall",
          //             "gorment@work_waterfall",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["gorment2_waterfall", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2_waterfall",
          //             "Flipup@work_waterfall",
          //             "gorment@work_waterfall",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment2@work_waterfall"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Gromet",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_waterfall",
          //             "Raceway",
          //             "gorment_waterfall",
          //             "gorment2_waterfall",
          //             "Flipup@work_waterfall",
          //             "gorment@work_waterfall",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["gorment3_waterfall", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2_waterfall",
          //             "Flipup@work_waterfall",
          //             "gorment@work_waterfall",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment3@work_waterfall"],
          //         },
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "Side Radius Raceway Options",
          //   required: false,
          //   thumbnail: "/assets/components/ColorsSharing.png",
          //   rules: [
          //     {
          //       type: hide,
          //       node: ["Flipup", "gorment", "Raceway"],
          //     },
          //   ],
          //   options: [
          //     {
          //       name: "Flip Up",
          //       thumbnail: "assets/system/thumbnails/Parts/flipup.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       default: true,
          //       enableOptions: ["Mid Prelam", "Mid Metal"],

          //       rules: [
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["Flipup_radious", "connecter", "mid_leg"],
          //         },
          //         {
          //           type: hide,

          //           node: [
          //             "Table_top_radious",
          //             "gorment2_radious",
          //             "gorment_radious",
          //             "gorment@work_radious",
          //             "mid_leg_gorment",
          //             "gorment2@work_radious",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["Eco", "Noah", "Scope"],
          //             },
          //           ],
          //           node: [
          //             "Raceway",
          //             "gorment_radious",
          //             "gorment2_radious",
          //             "gorment3_radious",

          //             "gorment@work_radious",
          //             "gorment2@work_radious",
          //           ],
          //         },

          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "Flipup_radious",
          //             "gorment2@work_radious",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           ////
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["Flipup@work_radious"],
          //         },
          //       ],
          //     },

          //     {
          //       name: "Gromet with vertical raceway",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],

          //       disableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_radious",
          //             "Raceway",
          //             "gorment2_radious",
          //             "gorment3_radious",

          //             "Flipup@work_radious",
          //             "connecter",
          //             "mid_leg",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: [
          //             "gorment_radious",
          //             "Table_top_radious",
          //             "mid_leg_gorment",
          //           ],
          //         },

          //         {
          //           type: hide,
          //           node: [
          //             "mid_leg",
          //             "prelammid",
          //             "prelammid@work",
          //             "metalmodestymid",
          //             "metalmodestymid@work",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment_radious", "gorment2@work_radious"],
          //         },

          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],

          //           node: ["gorment@work_radious"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Gromet With faceplate Cutting",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_radious",
          //             "Raceway",
          //             "gorment_radious",
          //             "gorment2_radious",
          //             "gorment3_radious",
          //             "Flipup@work_radious",
          //             "gorment@work_radious",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["gorment2_radious", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2_radious",
          //             "Flipup@work_radious",
          //             "gorment@work_radious",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment2@work_radious"],
          //         },
          //       ],
          //     },
          //     {
          //       name: "Only Gromet",
          //       thumbnail: "assets/system/thumbnails/Parts/gorment.png",
          //       // options: [...boxraceway],
          //       // enableFeatures: ["Main Modesty"],
          //       enableOptions: ["Mid Prelam", "Mid Metal"],
          //       rules: [
          //         {
          //           type: hide,
          //           node: [
          //             "Flipup_radious",
          //             "Raceway",
          //             "gorment_radious",
          //             "gorment2_radious",
          //             "Flipup@work_radious",
          //             "gorment@work_radious",
          //             "mid_leg_gorment",
          //           ],
          //         },
          //         {
          //           type: show,
          //           condition: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //           ],
          //           node: ["gorment3_radious", "connecter", "Table_top"],
          //         },
          //         {
          //           type: show,
          //           node: ["mid_leg"],
          //         },
          //         {
          //           type: hide,
          //           condition: [
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: [
          //             "gorment2_radious",
          //             "Flipup@work_radious",
          //             "gorment@work_radious",
          //             "connecter",
          //           ],
          //         },
          //         {
          //           type: show,
          //           multipleConditions: [
          //             {
          //               feature: "Table Top Options",
          //               values: ["Reverse waterfall"],
          //             },
          //             { operator: "AND" },
          //             {
          //               feature: "Legs",
          //               values: ["@work", "IO"],
          //             },
          //           ],
          //           node: ["gorment3@work_radious"],
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],

                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: [
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: ["Flipup", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup-_STYLE"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: ["gorment", "mid_leg_gorment"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment_STYLE"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: ["gorment2", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2_STYLE"],
                  },
                ],
              },
              {
                name: "Only Gromet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                    ],
                    node: ["gorment3", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Plain"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3_STYLE"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],

                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: ["Flipup_waterfall", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup_waterfall"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup-_STYLE_waterfall"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: ["gorment_waterfall", "mid_leg_gorment"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment_waterfall"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment_STYLE_waterfall"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: ["gorment2_waterfall", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2_waterfall"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2_STYLE_waterfall"],
                  },
                ],
              },
              {
                name: "Only Gromet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                    ],
                    node: ["gorment3_waterfall", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3_waterfall"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Reverse waterfall"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3_STYLE_waterfall"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                default: true,
                enableOptions: ["Mid Prelam", "Mid Metal"],

                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: ["Flipup_radious", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup_radious"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["Flipup-_STYLE_radious"],
                  },
                ],
              },

              {
                name: "Gromet with vertical raceway",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                disableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: ["gorment_radious", "mid_leg_gorment"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment_radious"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment_STYLE_radious"],
                  },
                ],
              },
              {
                name: "Gromet With faceplate Cutting",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },

                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: ["gorment2_radious", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2_radious"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment2_STYLE_radious"],
                  },
                ],
              },
              {
                name: "Only Gromet",
                thumbnail: "assets/system/thumbnails/Parts/gorment.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                enableOptions: ["Mid Prelam", "Mid Metal"],
                rules: [
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: [
                      "Flipup",
                      "Flipup-_STYLE",
                      "Flipup_waterfall",
                      "Flipup-_STYLE_waterfall",
                      "Flipup_radious",
                      "Flipup-_STYLE_radious",
                      "Table_top",
                      "Table_top_waterfall",
                      "Table_top_radious",
                      "gorment2",
                      "gorment",
                      "gorment3",
                      "gorment2_STYLE",
                      "gorment_STYLE",
                      "gorment3_STYLE",
                      "gorment2_waterfall",
                      "gorment_waterfall",
                      "gorment3_waterfall",
                      "gorment2_STYLE_waterfall",
                      "gorment_STYLE_waterfall",
                      "gorment3_STYLE_waterfall",
                      "gorment2_radious",
                      "gorment_radious",
                      "gorment3_radious",
                      "gorment2_STYLE_radious",
                      "gorment_STYLE_radious",
                      "gorment3_STYLE_radious",
                      "connecter",
                      "mid_leg",
                    ],
                  },
                  {
                    type: show,
                    condition: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                    ],
                    node: ["gorment3_radious", "connecter", "mid_leg"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3_radious"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      {
                        feature: "Table Top Options",
                        values: ["Side radius"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Styl", "@Work"] },
                    ],
                    node: ["gorment3_STYLE_radious"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Pedestal",
    rules: [
      {
        type: hide,
        node: ["LOCK", "NUMERIC_LOCK", "NUMERIC_LOCK2", "LOCK2", "LOCKback"],
      },
    ],
    options: [
      {
        name: "Cabin Table",
        required: false,
        multiSelect: false,
        rules: [],
        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            disableFeatures: ["Pedestal's Legs"],
            pid: "",
            rules: [
              {
                type: hide,
                node: ["ped"],
              },
              {
                type: hide,
                node: ["LOCK", "NUMERIC_LOCK", "NUMERIC_LOCK2", "LOCK2"],
              },
              ...sideTableLegRules,
            ],
          },

          {
            name: "2 Non Equal drawer pedestal With Regular lock",
            thumbnail: "assets/system/thumbnails/pedetals/22.png",
            size: 1.02,
            Length: 401,
            Depth: 451,
            enableFeatures: ["Pedestal's Legs"],
            rules: [
              {
                type: show,
                node: ["ped"],
              },

              {
                type: replacePart,
                node: ["ped"],
                pid: "https://content.helloviewer.io/uploads/9987ba73-2d05-4a8c-b492-e715b46f5967/1_6_2024/1719843642831/2ne.glb",
              },
              {
                type: "show",
                node: ["LOCK", "LOCK1", "LOCKback"],
              },
              {
                type: "hide",
                node: ["NUMERIC_LOCK"],
              },
              {
                type: hide,
                node: [
                  "DRAWER_RODHANDLE1",
                  "DRAWER_RODHANDLE2",
                  "DRAWER_RODHANDLE3",
                  "DRAWER_RODHANDLE4",
                  "DRAWER_SQRHANDLE1",
                  "DRAWER_SQRHANDLE2",
                  "DRAWER_SQRHANDLE3",
                  "DRAWER_SQRHANDLE4",
                  "Handle",

                  "C_handle",
                  "d_handle",
                ],
              },
              ...sideTableLegRules,
            ],
          },
          {
            name: "2 Non Equal drawer pedestal With Numeric Lock",
            thumbnail: "assets/system/thumbnails/pedetals/22.png",
            size: 1.05,
            Length: 401,
            Depth: 451,
            enableFeatures: ["Pedestal's Legs"],
            rules: [
              {
                type: show,
                node: ["ped", "NUMERIC_LOCK"],
              },

              {
                type: replacePart,
                node: ["ped"],
                pid: "https://content.helloviewer.io/uploads/9987ba73-2d05-4a8c-b492-e715b46f5967/1_6_2024/1719843642831/2ne.glb",
              },
              {
                type: "show",
                node: ["NUMERIC_LOCK"],
              },
              {
                type: "hide",
                node: ["LOCK1", "LOCKback"],
              },
              {
                type: hide,
                node: [
                  "DRAWER_RODHANDLE1",
                  "DRAWER_RODHANDLE2",
                  "DRAWER_RODHANDLE3",
                  "DRAWER_RODHANDLE4",
                  "DRAWER_SQRHANDLE1",
                  "DRAWER_SQRHANDLE2",
                  "DRAWER_SQRHANDLE3",
                  "DRAWER_SQRHANDLE4",
                  "Handle",

                  "C_handle",
                  "d_handle",
                ],
              },
              ...sideTableLegRules,
            ],
          },
          {
            name: "3 drawer pedestal With Regular lock",
            thumbnail: "assets/system/thumbnails/pedetals/3.png",
            size: 1.03,
            Length: 402,
            Depth: 452,
            enableFeatures: ["Pedestal's Legs"],
            rules: [
              {
                type: show,
                node: ["ped"],
              },

              {
                type: replacePart,
                node: ["ped"],
                pid: "https://content.helloviewer.io/uploads/c54858c3-2de5-4035-abfe-ab5d5185ebf1/1_6_2024/1719843496225/3.glb",
              },
              {
                type: "show",
                node: ["LOCK", "LOCK1", "LOCKback"],
              },
              {
                type: "hide",
                node: ["NUMERIC_LOCK"],
              },
              {
                type: hide,
                node: [
                  "DRAWER_RODHANDLE1",
                  "DRAWER_RODHANDLE2",
                  "DRAWER_RODHANDLE3",
                  "DRAWER_RODHANDLE4",
                  "DRAWER_SQRHANDLE1",
                  "DRAWER_SQRHANDLE2",
                  "DRAWER_SQRHANDLE3",
                  "DRAWER_SQRHANDLE4",
                  "Handle",
                  "C_handle",
                  "d_handle",
                ],
              },

              ...sideTableLegRules,
            ],
          },
          {
            name: "3 drawer pedestal With Numeric lock",
            thumbnail: "assets/system/thumbnails/pedetals/3.png",
            size: 1.04,
            Length: 402,
            Depth: 452,
            enableFeatures: ["Pedestal's Legs"],
            rules: [
              {
                type: show,
                node: ["ped"],
              },

              {
                type: replacePart,
                node: ["ped"],
                pid: "https://content.helloviewer.io/uploads/c54858c3-2de5-4035-abfe-ab5d5185ebf1/1_6_2024/1719843496225/3.glb",
              },
              {
                type: "show",
                node: ["NUMERIC_LOCK"],
              },
              {
                type: "hide",
                node: ["LOCK1", "LOCKback"],
              },
              {
                type: hide,
                node: [
                  "DRAWER_RODHANDLE1",
                  "DRAWER_RODHANDLE2",
                  "DRAWER_RODHANDLE3",
                  "DRAWER_RODHANDLE4",
                  "DRAWER_SQRHANDLE1",
                  "DRAWER_SQRHANDLE2",
                  "DRAWER_SQRHANDLE3",
                  "DRAWER_SQRHANDLE4",
                  "Handle",
                  "C_handle",
                  "d_handle",
                ],
              },

              ...sideTableLegRules,
            ],
          },
          // {
          //   name: "4 drawer pedestal",
          //   thumbnail: "assets/system/placeholder.svg",
          //   rules: [
          //     {
          //       type: show,
          //       node: ["ped"],
          //     },

          //     {
          //       type: replacePart,
          //       node: ["ped"],
          //       pid: "https://content.helloviewer.io/uploads/58860d08-3c8c-4b6d-a129-a170bf8fd876/31_4_2024/1717150589204/4.glb",
          //     },
          //     {
          //       type: hide,
          //       node: [
          //         "DRAWER_RODHANDLE1",
          //         "DRAWER_RODHANDLE2",
          //         "DRAWER_RODHANDLE3",
          //         "DRAWER_RODHANDLE4",
          //         "DRAWER_SQRHANDLE1",
          //         "DRAWER_SQRHANDLE2",
          //         "DRAWER_SQRHANDLE3",
          //         "DRAWER_SQRHANDLE4",
          //         "Handle",
          //         "C_handle",
          //         "Handle",
          //         "d_handle",
          //         "LOCK",
          //         "NUMERIC_LOCK",
          //         "NUMERIC_LOCK2",
          //         "LOCK2",
          //         "LOCK1",
          //       ],
          //     },
          //     ...sideTableLegRules,
          //   ],
          // },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",

                rules: [
                  {
                    type: show,
                    node: ["Eco"],
                  },
                  {
                    type: hide,
                    node: ["io", "@work", "Europa", "scope", "styl"],
                  },
                ],
              },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",

                rules: [
                  {
                    type: show,
                    node: ["@work"],
                  },
                  {
                    type: hide,
                    node: ["io", "Eco", "Europa", "scope", "styl"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                rules: [
                  {
                    type: show,
                    node: ["scope"],
                  },
                  {
                    type: hide,
                    node: ["io", "Eco", "Europa", "@work", "styl"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",

                rules: [
                  {
                    type: show,
                    node: ["io"],
                  },
                  {
                    type: hide,
                    node: ["scope", "Eco", "Europa", "@work", "styl"],
                  },
                ],
              },

              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",

                rules: [
                  {
                    type: show,
                    node: ["styl"],
                  },
                  {
                    type: hide,
                    node: ["scope", "Eco", "Europa", "@work", "io"],
                  },
                ],
              },
              {
                name: "Europa",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                rules: [
                  {
                    type: show,
                    node: ["Europa"],
                  },
                  {
                    type: hide,
                    node: ["scope", "Eco", "@work", "io", "styl"],
                  },
                ],
              },
            ],
          },
          {
            name: "Pedestal's Legs",

            nodeName: [""],
            required: false,

            options: [
              {
                name: "Without Castor",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color"],
                pid: "",

                rules: [
                  {
                    type: hide,
                    node: ["wheels"],
                  },
                  {
                    type: show,
                    node: ["base"],
                  },
                ],
              },
              {
                name: "With Castor",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color"],
                pid: "",

                rules: [
                  {
                    type: show,
                    node: ["wheels"],
                  },
                  {
                    type: hide,
                    node: ["base"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Handle",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,

        options: [
          {
            name: "Handle",

            nodeName: [
              "DRAWER1",
              "DRAWER2",
              "DRAWER3",
              "DRAWER4",
              "DRAWER_RODHANDLE1",
              "DRAWER_RODHANDLE2",
              "DRAWER_RODHANDLE3",
              "DRAWER_RODHANDLE4",
              "DRAWER_SQRHANDLE1",
              "DRAWER_SQRHANDLE2",
              "DRAWER_SQRHANDLE3",
              "DRAWER_SQRHANDLE4",
              "Handle",
              "C_handle",
              "Handle",
              "d_handle",
              "D_handle",
              "c_handle",
            ],
            required: false,

            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color"],
                pid: "",

                rules: [
                  {
                    type: show,
                    node: [, "DRAWER1", "DRAWER2", "DRAWER3", "DRAWER4"],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "DRAWER_RODHANDLE4",
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "Handle",
                      "C_handle",
                      "Handle",
                      "d_handle",
                      "D_handle",
                      "c_handle",
                      "storage_door_FLUSH",
                      "Back_storage_door_FLUSH",
                      "storage_drawer_FLUSH",
                    ],
                  },
                ],
              },
              {
                name: "D Metal",
                thumbnail: "assets/system/thumbnails/pedes/metal.png",
                disableFeatures: ["Color"],
                rules: [
                  {
                    type: show,
                    node: [
                      "d_handle",
                      "D_handle",

                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "storage_door_NO",
                      "Back_storage_door_NO",
                      "storage_drawer_NO",
                      "DRAWER_RODHANDLE4",
                      "Handle",

                      "D_handleback",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "storage_door_FLUSH",
                      "Back_storage_door_FLUSH",
                      "storage_drawer_FLUSH",
                      "c_handle",
                      "C_handle",
                      "c_handleback",
                    ],
                  },
                ],
              },
              {
                name: "Flush",
                thumbnail: "assets/system/thumbnails/pedes/cut.png",
                enableFeatures: ["Aluminium Panel Options", "Color"],
                enableOptions: ["WHITE", "GREY", "LIGHTGREY"],
                rules: [
                  {
                    type: show,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "storage_door_FLUSH",
                      "Back_storage_door_FLUSH",
                      "storage_drawer_FLUSH",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_RODHANDLE1",
                      "storage_door_NO",
                      "c_handleback",
                      "D_handleback",

                      "Back_storage_door_NO",
                      "storage_drawer_NO",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "DRAWER_RODHANDLE4",
                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                      "Handle",
                      "C_handle",
                      "d_handle",
                      "c_handle",
                      "D_handle",
                    ],
                  },
                ],
              },
              {
                name: "C-Metal",
                thumbnail: "assets/system/thumbnails/pedes/cut.png",
                disableFeatures: ["Color"],
                rules: [
                  {
                    type: show,
                    node: [
                      "C_handle",
                      "c_handle",

                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "storage_door_NO",
                      "Back_storage_door_NO",
                      "storage_drawer_NO",
                      "DRAWER_RODHANDLE4",
                      "c_handleback",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "storage_door_FLUSH",
                      "Back_storage_door_FLUSH",
                      "storage_drawer_FLUSH",
                      "D_handleback",
                      "Back_storage_door_FLUSH",
                      "Handle",
                      "D_handle",
                      "d_handle",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Handle's Color",
            required: false,
            options: [
              {
                name: "WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
                  },
                ],
              },
              {
                name: "GREY",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
                  },
                ],
              },
              {
                name: "LIGHTGREY",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Privacy",
    multiSelect: false,

    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,

        options: [
          {
            name: "Side Modesty",
            nodeName: [
              "prelammodestymid",
              "metalmodestymid",
              "prelamright",
              "metalmodestyright",
            ],
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelamright",
                      "metalmodestymid",
                      "metalmodestymidEco2",
                      "prelammodestymidEco2",
                      "metalmodestyright",
                    ],
                  },
                ],
              },
              {
                name: "Prelam",
                thumbnail: "assets/system/thumbnails/Parts/Prelamside.png",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodestymid",
                      "metalmodestymidEco2",
                      "prelammodestymidEco2",
                      "metalmodestyright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["prelammodestymid"],
                  },

                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Depth",
                        values: [600, 700, 750],
                      },
                    ],
                    node: ["prelammodestymid"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Depth",
                        values: [600, 700, 750],
                      },
                    ],
                    node: "prelammodestymidEco2",
                  },
                ],
              },
              {
                name: "Metal",
                thumbnail: "assets/system/thumbnails/Parts/metalmodesty.png",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "prelammodestymid",
                      "metalmodestymidEco2",
                      "prelammodestymidEco2",
                      "prelamright",
                    ],
                  },
                  {
                    type: "show",
                    node: ["metalmodestymid", "metalmodestyright"],
                  },
                  {
                    type: hide,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Depth",
                        values: [600, 700, 750],
                      },
                    ],
                    node: ["metalmodestymid"],
                  },
                  {
                    type: show,
                    multipleConditions: [
                      { feature: "Plain Raceway Options", values: ["Flip Up"] },
                      { operator: "OR" },
                      {
                        feature: "Reverse Waterfall Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "OR" },
                      {
                        feature: "Side Radius Raceway Options",
                        values: ["Flip Up"],
                      },
                      { operator: "AND" },
                      { feature: "Legs", values: ["Eco"] },
                      {
                        operator: "AND",
                      },
                      {
                        feature: "Depth",
                        values: [600, 700, 750],
                      },
                    ],
                    node: "metalmodestymidEco2",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Storage Facia",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              },
            ],
          },
          {
            name: "plain DefaultMaterial BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood3"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Storage Side",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              },
            ],
          },
          {
            name: "plain DefaultMaterial BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["WOOD_Sidestorage"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal Facia",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              },
            ],
          },
          {
            name: "plain DefaultMaterial BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: ["Wood2", "WOOD"],
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal Side",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              },
            ],
          },
          {
            name: "plain DefaultMaterial BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Pedestal Top",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Mangfall_Beech.jpg",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Acacia.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Urban_Teak.jpg",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Cairo_Walnut_BaseColor.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Maldau_Acacia_BaseColor.png",
              },
            ],
          },
          {
            name: "plain DefaultMaterial BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/plain_DefaultMaterial_BaseColor.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Wood/Thansau_Maple_BaseColor.png",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
