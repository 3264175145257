import placeholderImage from "../assets/images/landing-page-image-6.jpg";
import checkIcon from "../assets/icons/check.svg";
// import ReactTooltip from "react-tooltip";
import { useAppSelector } from "../hooks/hooks";
import { useEffect } from "react";
import { Feature, Option } from "../types/types";
import { makeSessionRequest } from "../utils/makeSessionRequest";

const RenderThumbnails = ({
  item,
  handleOptionSelection,
  getSelectedOption,
}: {
  item: Feature;
  handleOptionSelection: (obj: any) => void;
  getSelectedOption: (name: string) => Option[];
}) => {
  const userConfig2 = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );
  console.log(userConfig2);
    const disabledOptions = useAppSelector(
    (state) => state.configuration.disabledOptions
  );
  const disabledFeatures = useAppSelector(
    (state) => state.configuration.disabledFeatures
  );
  let newDisabledFeatures: string[] = [];

  if (userConfig2["Wire Access"] && userConfig2["Wire Access"] !== "D-cut") {
    if (userConfig2["Wire Access"] === "Gromet") {
      newDisabledFeatures = ["FlipUp Position", ...disabledFeatures];
    } else if (userConfig2["Wire Access"] === "FlipUp") {
      newDisabledFeatures = ["Gromet Position", ...disabledFeatures];
    }
  } else {
    newDisabledFeatures = [
      ...newDisabledFeatures,
      "FlipUp Position",
      "Gromet Position",
    ];
  }

  //Navdeep commented this on 17:59 10-06-2024 to debug flickering issue ,whenever a single object is there for single subcategory

  // useEffect(() => {
  //   // select the first option if only one option exists
  //   const options = (item.options as Option[])?.filter(
  //     (option) => !disabledOptions.includes(option.name)
  //   );
  //   if (!options) {
  //     return;
  //   }
  //   const option = options[0];
  //   if (
  //     options.length > 0 &&
  //     getSelectedOption(item.name)[0]?.name === option.name
  //   ) {
  //     return;
  //   }
  //   if (options.length === 1) {
  //     handleOptionSelection({
  //       name: item.name,
  //       nodeName: item.nodeName,
  //       multiSelect: item.multiSelect,
  //       option,
  //       rules: item.rules,
  //     });
  //   }
  // }, [
  //   disabledOptions,
  //   getSelectedOption,
  //   handleOptionSelection,
  //   item.name,
  //   item.nodeName,
  //   item.options,
  //   item.rules,
  //   item.multiSelect,
  // ]);

  const isOptionSelected = (optionName: string) => {
    return getSelectedOption(item.name)
      .map((option) => option.name)
      .includes(optionName);
    // item.multiSelect?:getSelectedOption(item.name)?.name===optionName;
  };

  return (
    <div className="px-4">
      {item.name && (
        <div className="flex cursor-pointer flex-col items-center">
          <h1 className=" mt-4 text-xs 2xl:text-base font-bold mr-auto items-center justify-center">
            {item.name.toUpperCase()}
          </h1>
        </div>
      )}
      <div className="grid grid-cols-3 w-full  px-1">
        {(item.options as Option[] | undefined)
          ?.filter((option) => !disabledOptions.includes(option.name))
          .map((option, index) => {
            return (
              <div
                key={option.name}
                onClick={() => {
                  makeSessionRequest("selected_option", {
                    ...(item.name && { itemName: item.name }),
                    selectedOption: option.name,
                  });
                  handleOptionSelection({
                    name: item.name,
                    nodeName: item.nodeName,
                    option,
                    rules: item.rules,
                    multiSelect: item.multiSelect,
                  });
                }}
                className="cursor-pointer pt-5 mb-1 px-2 w-full flex flex-col items-center relative"
              >
                <img
                  src={option.thumbnail || placeholderImage}
                  className=" w-full border border-black border-opacity-20 "
                  alt=""
                />

                {isOptionSelected(option.name) && (
                  <img
                    src={checkIcon}
                    alt=""
                    className="absolute top-2 right-1 "
                  />
                )}

                <h1
                  // style={{ fontSize: ".6rem" }}
                  className=" whitespace-normal text-xs xl:text-xs mt-3 w-full break-words"
                  data-tip={option.name.length > 15 ? option.name : null}
                >
                  {option.name}
                </h1>
              </div>
            );
          })}
      </div>
      {getSelectedOption(item.name)
        ?.map((option) => option.options as Feature[] | undefined)
        ?.flat()
        .filter(
          (subFeature) =>
            subFeature?.name && !newDisabledFeatures.includes(subFeature?.name)
        )
        .map((subFeature) =>
          subFeature ? (
            <RenderThumbnails
              item={subFeature}
              getSelectedOption={getSelectedOption}
              handleOptionSelection={handleOptionSelection}
            />
          ) : null
        )}
    </div>
  );
};

export default RenderThumbnails;
