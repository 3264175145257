// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "2 EQUAL DRAWER PEDESTAL",
           "Table Top Options": "Plain",
           Category: "Pedestals",
            size: 1.01,
            Length:400,
            Depth:450,
           
            
            thumbnail: "assets/system/thumbnails/pedetals/2.png",
            productId: "664b5fddb6bc795c27943167",
            disableOptions: ["WHITE", "GREY", "LIGHTGREY"],
            disableFeatures: ["Color","Handle's Color",],
            enableOptions: ["Double Numeric Lock", "Double Cylindrical Lock"],

            
          },
          {
            name: "2 NON-EQUAL DRAWER",
            disableOptions: [
              "Double Numeric Lock",
              "Double Cylindrical Lock",
              "Aluminium Panel Options",
            ],
            Category: "Pedestals",
            disableFeatures: ["Color","Handle's Color",],
            size: 1.02,
            Length: 401,
            Depth: 451,
            "Table Top Options": "Plain",
            thumbnail: "assets/system/thumbnails/pedetals/22.png",
            productId: "664c9b8f0ff6faf3f6040e78",
          },

          {
            name: "3 DRAWER PEDESTAL",
            disableOptions: [
              "Double Numeric Lock",
              "Double Cylindrical Lock",
              "Aluminium Panel Options",
            ],
            Category: "Pedestals",
            disableFeatures: ["Color","Handle's Color",],
            size: 1.03,
            Length: 402,
            Depth: 452,
            "Table Top Options": "Plain",
            thumbnail: "assets/system/thumbnails/pedetals/3.png",
            productId: "664ca88d71339888fda390e1",
          },
          {
            name: "4 DRAWER PEDESTAL",
            disableOptions: [
              "Double Numeric Lock",
              "Double Cylindrical Lock",
              "Aluminium Panel Options",
            ],
            Category: "Pedestals",
            disableFeatures: ["Color","Handle's Color",],
            size: 1.04,
            Length: 403,
            Depth: 453,
            "Table Top Options": "Plain",
            thumbnail: "assets/system/thumbnails/pedetals/4.png",
            productId: "664cb2533e16dc2f6167c8be",
          },
        ],
      },
    ],
  },

  {
    name: "Handle",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,

        options: [
          {
            name: "Handle",

            nodeName: [
              "DRAWER1",
              "DRAWER2",
              "DRAWER3",
              "DRAWER4",
              "DRAWER_RODHANDLE1",
              "DRAWER_RODHANDLE2",
              "DRAWER_RODHANDLE3",
              "DRAWER_RODHANDLE4",
              "DRAWER_SQRHANDLE1",
              "DRAWER_SQRHANDLE2",
              "DRAWER_SQRHANDLE3",
              "DRAWER_SQRHANDLE4",
              "Handle",
              "C_handle",
              "Handle",
              "d_handle",
            ],
            required: false,

            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color","Handle's Color",],
                pid: "",

                rules: [
                  {
                    type: show,
                    node: [, "DRAWER1", "DRAWER2", "DRAWER3", "DRAWER4"],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "DRAWER_RODHANDLE4",
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "Handle",
                      "C_handle",
                      "Handle",
                      "d_handle",
                    ],
                  },
                ],
              },
              {
                name: "D Metal",
                thumbnail: "assets/system/thumbnails/pedes/metal.png",
                disableFeatures: ["Color","WHITE", "GREY", "LIGHTGREY","Handle's Color",],
                rules: [
                  {
                    type: show,
                    node: [
                      "d_handle",
                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",

                      "C_handle",
                    ],
                  },
                ],
              },
              {
                name: "Flush",
                thumbnail: "assets/system/thumbnails/pedes/cut.png",
                enableFeatures: ["Aluminium Panel Options", "Color","Handle's Color",],
                enableOptions: ["WHITE", "GREY", "LIGHTGREY"],
                rules: [
                  {
                    type: show,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "DRAWER_RODHANDLE4",
                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                      "Handle",
                      "C_handle",
                      "d_handle",
                    ],
                  },
                ],
              },
              {
                name: "C-Metal",
                thumbnail: "assets/system/thumbnails/pedes/cut.png",
                disableFeatures: ["Color","WHITE", "GREY", "LIGHTGREY","Handle's Color",],
                rules: [
                  {
                    type: show,
                    node: [
                      "C_handle",
                      "DRAWER1",
                      "DRAWER2",
                      "DRAWER3",
                      "DRAWER4",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "DRAWER_SQRHANDLE1",
                      "DRAWER_SQRHANDLE2",
                      "DRAWER_SQRHANDLE3",
                      "DRAWER_SQRHANDLE4",
                      "DRAWER_RODHANDLE1",
                      "DRAWER_RODHANDLE2",
                      "DRAWER_RODHANDLE3",
                      "DRAWER_RODHANDLE4",

                      "Handle",
                      "d_handle",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Handle's Color",
            required: false,
            options: [
              {
                name: "WHITE",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/Off_White_diffuse.png",
                  },
                ],
              },
              {
                name: "GREY",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/Graphite_Grey_diffuse.png",
                  },
                ],
              },
              {
                name: "LIGHTGREY",
                thumbnail:
                  "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
                rules: [
                  {
                    type: "applyMaterial",
                    node: "SQR_HANDLE",
                    textureUrl:
                      "https://content.helloviewer.io/officemate/textures/Wood/SILVER_GREY_DIFFUSE.png",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,

        options: [
          {
            name: "Pedestal's Legs",

            nodeName: [""],
            required: false,

            options: [
              {
                name: "Without Castor",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color"],
                pid: "",

                rules: [
                  {
                    type: hide,
                    node: ["wheels"],
                  },
                  {
                    type: show,
                    node: ["base"],
                  },
                ],
              },
              {
                name: "With Castor",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Color"],
                pid: "",

                rules: [
                  {
                    type: show,
                    node: ["wheels"],
                  },
                  {
                    type: hide,
                    node: ["base"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Lock",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          
          {
            name: "Cylindrical Lock",
            thumbnail: "assets/system/thumbnails/pedes/metal.png",
            rules: [
              {
                type: show,
                node: ["LOCK"],
              },
              {
                type: hide,
                node: ["NUMERIC_LOCK", "LOCK2", "NUMERIC_LOCK2"],
              },
            ],
          },
          {
            name: "Numeric Lock",
            thumbnail: "assets/system/thumbnails/pedes/numeric.png",
            rules: [
              {
                type: show,
                node: ["NUMERIC_LOCK"],
              },
              {
                type: hide,
                node: ["LOCK", "LOCK2", "NUMERIC_LOCK2"],
              },
            ],
          },
          {
            name: "Double Cylindrical Lock",
            thumbnail: "assets/system/thumbnails/pedes/numeric.png",
            rules: [
              {
                type: show,
                node: ["LOCK2"],
              },
              {
                type: hide,
                node: ["LOCK", "NUMERIC_LOCK", "NUMERIC_LOCK2"],
              },
            ],
          },
          {
            name: "Double Numeric Lock",
            thumbnail: "assets/system/thumbnails/pedes/numeric.png",
            rules: [
              {
                type: show,
                node: ["NUMERIC_LOCK2"],
              },
              {
                type: hide,
                node: ["LOCK", "NUMERIC_LOCK", "LOCK2"],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Cushion",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: hide,
                node: ["Cushion"],
              },
            ],
          },
          {
            name: "Cushion",
            thumbnail: "assets/system/thumbnails/pedes/cushion.png",
            rules: [
              {
                type: show,
                node: ["Cushion"],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal Facia",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal Side",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "WOOD_Side",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal Top",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "top_wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Pedestals",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "CUSHION_FABRIC",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;