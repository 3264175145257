import React from "react";
import { Product } from "../types/types";
import * as XLSX from 'xlsx';

const BomTable = ({ bom }: { bom: Product["bom"] }) => {
  
  const handleDownload = () => {
    const data = bom.flatMap((item:any) => {
      return Object.keys(item.BOM)
        .filter(bomKey => item.BOM[bomKey].trim() !== "")
        .map((bomKey) => {
          const bomLines = item.BOM[bomKey].split("\n");
          const type = bomLines.find((line:any) => line.startsWith("Type:"))?.replace("Type:", "").trim() || "";
          const size = bomLines.find((line:any) => line.startsWith("Size:"))?.replace("Size:", "").trim() || "";
          const hardware = bomLines.find((line:any) => line.startsWith("Hardware:"))?.replace("Hardware:", "").trim() || "";
          const qty = bomLines.find((line:any) => line.startsWith("Qty:"))?.replace("Qty:", "").trim() || "";

          return {
            Component: bomKey,
            Type: type,
            Size: size,
            Hardware: hardware,
            Qty: qty,
            // Price: item.price // Uncomment if price is to be included
          };
        });
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BOM");
    XLSX.writeFile(workbook, "bom.xlsx");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h1 className="mb-2">BOM:</h1>
        </div>
        <div>
          <button onClick={handleDownload}>
            Download as Excel
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr className="text-left border">
            <th className="px-4 py-2">Component</th>
            <th className="px-4 py-2">Type</th>
            <th className="px-4 py-2">Size</th>
            <th className="px-4 py-2">Hardware</th>
            <th className="px-4 py-2">Quantity</th>
            {/* <th className="px-4 py-2">Price</th> */}
          </tr>
        </thead>
        <tbody className="border">
          {bom.flatMap((item:any) => 
            Object.keys(item.BOM)
              .filter(bomKey => item.BOM[bomKey].trim() !== "")
              .map((bomKey) => {
                const bomLines = item.BOM[bomKey].split("\n");
                const type = bomLines.find((line:any) => line.startsWith("Type:"))?.replace("Type:", "").trim() || "";
                const size = bomLines.find((line:any) => line.startsWith("Size:"))?.replace("Size:", "").trim() || "";
                const hardware = bomLines.find((line:any) => line.startsWith("Hardware:"))?.replace("Hardware:", "").trim() || "";
                const qty = bomLines.find((line:any) => line.startsWith("Qty:"))?.replace("Qty:", "").trim() || "";

                return (
                  <tr key={`${item.name}-${bomKey}`} className="border">
                    <td className="px-4 py-1">{bomKey}</td>
                    <td className="px-4 py-1">{type}</td>
                    <td className="px-4 py-1">{size}</td>
                    <td className="px-4 py-1">{hardware}</td>
                    <td className="px-4 py-1">{qty}</td>
                    {/* <td className="px-4 py-1">{prices[index]}</td> */}
                  </tr>
                );
              })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BomTable;
