import { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import {
  getAllBankDetails,
  getDetails,
  PatchDataType,
} from "../utils/getBankDetails";
import { useHistory } from "react-router-dom";
import rightArrowIcon from "../assets/icons/right-arrow.svg";
import { patchDetails } from "../utils/getBankDetails";
import { uploadSheet } from "../utils/uploadPricingSheet";
import toast from "react-hot-toast";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import TestPricingModal from "../Components/TestPricingModal";
import { getIsSuperAdmin } from "../utils/getIsSuperAdmin";
import ConfirmAction from "../utils/confirmAction";
import Spinner from "../Components/_spinner/Spinner";
const Settings = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountType, setAccountType] = useState("");
  const [dealerAddress, setDealerAddress] = useState("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [micrCode, setMicrCode] = useState("");
  const [depthBased, setDepthBased] = useState<null | FileList>(null);
  const [lengthBased, setLengthBased] = useState<null | FileList>(null);
  const [accessories, setAccessories] = useState<null | FileList>(null);
  const [otherTables, setOtherTables] = useState<null | FileList>(null);
  const [showTestPriceModal, setShowTestPriceModal] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | undefined>(
    undefined
  );
  const [gstNumber, setGstNumber] = useState<string>("");
  const [currentTag, setCurrentTag] = useState<string | undefined>(undefined);
  const [allGroupDetails, setAllGroupDetails] = useState<
    { key: string; value: any }[]
  >([]);
  const [isCurrentDataChanged, setIsCurrentDataChanged] = useState(false);
  useEffect(() => {
    const superAdmin = getIsSuperAdmin();
    const userString = localStorage.getItem("user");
    const user = userString ? JSON.parse(userString) : undefined;
    if (!superAdmin && user && user.role !== 2) {
      history.push("/dashboard");
    }
    if (superAdmin) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
  }, [history]);

  useEffect(() => {
    const bankDetails = async () => {
      if (!getIsSuperAdmin()) {
        try {
          const allDetails = await getDetails();
          detailsSetter(allDetails);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          toast.custom("Bank details not found. Please Add bank details", {
            duration: 3000,
          });
        }
      }
    };
    if (!isSuperAdmin) {
      bankDetails();
    } else {
      (async () => {
        let allGroups = await getAllBankDetails();
        setAllGroupDetails(allGroups);
        setCurrentTag(allGroups[0].key);
        detailsSetter(allGroups[0].value);
        setIsLoading(false);
      })();
    }
    makeSessionRequest("settings_view");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin]);

  const singleDetailSetter = (
    item: string,
    setItem: (item: string) => void
  ) => {
    if (item) {
      setItem(item);
    } else {
      setItem("");
    }
  };

  const detailsSetter = (groupValue: any) => {
    const { bank_details, address, gst_number } = groupValue;
    const {
      beneficiary_name,
      bank_name,
      branch,
      account_type,
      account_number,
      ifsc,
      swift_code,
      micr,
    } = bank_details;
    singleDetailSetter(bank_name, setBankName);
    singleDetailSetter(branch, setBranch);
    singleDetailSetter(account_type, setAccountType);
    singleDetailSetter(account_number, setAccountNumber);
    singleDetailSetter(ifsc, setIfscCode);
    singleDetailSetter(swift_code, setSwiftCode);
    singleDetailSetter(micr, setMicrCode);
    singleDetailSetter(beneficiary_name, setBeneficiaryName);
    singleDetailSetter(address, setDealerAddress);
    singleDetailSetter(gst_number, setGstNumber);
  };

  const onSheetUpload = async (files: FileList | null, type: string) => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files[0]);
      try {
        makeSessionRequest("sheet_upload", { type: type });
        const res = await uploadSheet(formData, type);
        if (res.data.status) {
          toast.success("Pricing updated");
        } else {
          toast.error(
            `Uploaded file didn't match the pricing structure based on ${type}`
          );
        }
      } catch (error) {
        toast.error("Unable to upload file");
        makeSessionRequest("sheet_upload_failed", { type: type });
      }
    } else {
      toast.error("Please select a file to upload");
    }
  };

  const onSubmit = async () => {
    const onPatch = async () => {
      try {
        const patchData: PatchDataType = {
          bank_details: {
            beneficiary_name: beneficiaryName,
            bank_name: bankName,
            branch: branch,
            account_type: accountType,
            account_number: accountNumber,
            ifsc: ifscCode,
            swift_code: swiftCode,
            micr: micrCode,
          },
          address: dealerAddress,
          gst_number: gstNumber,
        };
        await patchDetails(patchData, currentTag);
        const unchangedData: { key: any; value: any }[] =
          allGroupDetails?.filter((group: any) => group.key !== currentTag);
        const changedData = { key: currentTag, value: patchData };
        setAllGroupDetails([...unchangedData, changedData]);
        setIsCurrentDataChanged(false);
        makeSessionRequest("updated_details");
        toast.success("Details Updated");
      } catch (error) {
        console.log(error);
        makeSessionRequest("update_details_failed");

        toast.error("Failed to update details");
      }
    };
    ConfirmAction("Do you want to update details?", "", onPatch);
  };

  const handleBackClick = () => {
    const goBack = () => {
      history.goBack();
      makeSessionRequest("back_to_dashboard");
    };
    if (isCurrentDataChanged) {
      isSuperAdmin
        ? ConfirmAction(
            `Are you sure? You'll lose the changes you made for group: ${currentTag
              ?.split("_")[1]
              .toUpperCase()}`,
            "",
            goBack
          )
        : ConfirmAction(
            `Are you sure? You'll lose the changes you have made.`,
            "",
            goBack
          );
    } else {
      goBack();
    }
  };

  const onBankChange = (e: any) => {
    e.preventDefault();
    const selectedGroup = e.target.value;
    if (isCurrentDataChanged) {
      ConfirmAction(
        `Are you sure? You'll lose the changes you made for group: ${currentTag
          ?.split("_")[1]
          .toUpperCase()}`,
        "",
        () => {
          const groupData = allGroupDetails?.find(
            (group) => group.key === selectedGroup
          );
          setCurrentTag(selectedGroup);
          detailsSetter(groupData?.value);
          setIsCurrentDataChanged(false);
        }
      );
    } else {
      const groupData = allGroupDetails?.find(
        (group) => group.key === selectedGroup
      );
      setCurrentTag(selectedGroup);
      detailsSetter(groupData?.value);
      setIsCurrentDataChanged(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="flex flex-col justify-center items-center">
      <TestPricingModal
        show={showTestPriceModal}
        closeForm={() => setShowTestPriceModal(false)}
      />
      <div className="flex w-full items-center py-4 px-8">
        <img src={logo} className=" w-62 h-auto" alt="" />
      </div>

      <div
        className="flex flex-col justify-center items-center w-full px-16"
        style={{ maxWidth: "1600px" }}
      >
        <div className="flex items-center justify-between w-full">
          <div
            onClick={handleBackClick}
            className="flex mr-auto cursor-pointer hide-while-download"
          >
            <img src={rightArrowIcon} className="mr-2" alt="back" />
            <h1 className="font-bold">Back</h1>
          </div>

          {/* {isSuperAdmin && (
            <button
              type="button"
              className="tracking-widest text-xs my-4 mr-10 py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
              onClick={() => {
                setShowTestPriceModal(true);
                makeSessionRequest("tested_pricing");
              }}
            >
              Test Pricing
            </button>
          )} */}
          <button
            type="submit"
            className="tracking-widest text-xs my-4  py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
            onClick={onSubmit}
          >
            Save Details
          </button>
        </div>
        <div className="grid grid-cols-1 mx-10 mt-8">
          {isSuperAdmin && (
            <div className="flex items-center mb-5">
              <h1 className="font-bold text-lg ">GROUP :</h1>
              <select
                name="select"
                value={currentTag}
                onChange={onBankChange}
                className="ml-5 px-1 py-2 text-lg"
                id="select"
              >
                {allGroupDetails?.map((details: any, index: number) => (
                  <option key={index} value={details.key}>
                    {details.key.split("_")[1].toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="" id="bank_details">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="p-2 flex flex-col overflow-y-auto"
              style={{ minWidth: "600px" }}
            >
              <div className="flex flex-col xl:flex-row">
                <div className="">
                  <div className="flex mb-4">
                    <h1 className="font-bold text-lg ">Bank Details</h1>
                  </div>
                  <div className="flex">
                    <div className="w-96">
                      <p className="mb-2">Name of the Bank: </p>
                      <input
                        className=" px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={bankName}
                        onChange={(e) => {
                          setBankName(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                      <p className="mb-2">Branch Name: </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        inputMode="tel"
                        value={branch}
                        onChange={(e) => {
                          setBranch(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />

                      <p className="mb-2">Account Number: </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={accountNumber}
                        onChange={(e) => {
                          setAccountNumber(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                      <p className="mb-2">Account Type: </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={accountType}
                        onChange={(e) => {
                          setAccountType(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                    </div>
                    <div className="w-px bg-gray-200 mx-5"></div>
                    <div className="w-96">
                      <p className="mb-2">IFSC Code : </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={ifscCode}
                        onChange={(e) => {
                          setIfscCode(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                      <p className="mb-2">SWIFT Code: </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={swiftCode}
                        onChange={(e) => {
                          setSwiftCode(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                      <p className="mb-2">MICR Code: </p>
                      <input
                        className="px-4 py-2 w-full focus:outline-none border mb-4"
                        type="text"
                        value={micrCode}
                        onChange={(e) => {
                          setMicrCode(e.target.value);
                          setIsCurrentDataChanged(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-px bg-gray-200 mx-5"></div>
                <div className="h-px bg-gray-200 my-5 xl:hidden"></div>

                <div className="w-96 mt-5 xl:mt-0">
                  <div className="flex mb-4">
                    <h1 className="font-bold text-lg ">Dealer Details</h1>
                  </div>
                  <p className="mb-2">Beneficiary Name: </p>
                  <input
                    className="px-4 py-2 w-full focus:outline-none border mb-4"
                    type="text"
                    value={beneficiaryName}
                    onChange={(e) => {
                      setBeneficiaryName(e.target.value);
                      setIsCurrentDataChanged(true);
                    }}
                  />
                  <p className="mb-2">Address: </p>
                  <textarea
                    className="px-4 py-2 w-full focus:outline-none border mb-4"
                    value={dealerAddress}
                    onChange={(e) => {
                      setDealerAddress(e.target.value);
                      setIsCurrentDataChanged(true);
                    }}
                    style={{ minHeight: "8rem", maxHeight: "12rem" }}
                  />
                  <div className="flex mb-4">
                    <h1 className="font-bold text-lg ">GST Number</h1>
                  </div>
                  <input
                    className="px-4 py-2 w-full focus:outline-none border mb-4"
                    type="text"
                    value={gstNumber}
                    onChange={(e) => {
                      setGstNumber(e.target.value);
                      setIsCurrentDataChanged(true);
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="h-px bg-gray-200 my-5"></div>
          {/* {isSuperAdmin && (
            <div className="flex flex-col col-span-1 mb-8" id="file_upload">
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="flex mb-4">
                    <h1 className="font-bold text-lg ">
                      Workstation Pricing Details
                    </h1>
                  </div>
                  <div>
                    <div className="flex flex-col md:flex-row items-center w-full justify-between lg:w-4/5">
                      <p className="w-2/5">Height Based Components: </p>
                      <input
                        className="ml-auto"
                        type="file"
                        accept=".xlsx, .csv"
                        onChange={(e) => setDepthBased(e.target.files)}
                      />
                      <button
                        id="uploadButton"
                        className="tracking-widest text-xs ml-5 py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
                        onClick={() => onSheetUpload(depthBased, "depth")}
                      >
                        Upload
                      </button>
                    </div>
                    <div className="flex flex-col md:flex-row items-center w-full justify-between mt-5 lg:w-4/5">
                      <p className="w-2/5">Length Based Components: </p>
                      <input
                        className="ml-auto"
                        type="file"
                        accept=".xlsx, .csv"
                        onChange={(e) => setLengthBased(e.target.files)}
                      />

                      <button
                        id="uploadButton"
                        className="tracking-widest text-xs ml-5 py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
                        onClick={() => onSheetUpload(lengthBased, "length")}
                      >
                        Upload
                      </button>
                    </div>
                    <div className="flex flex-col md:flex-row items-center w-full justify-between mt-5 lg:w-4/5">
                      <p className="w-2/5">Accessories: </p>
                      <input
                        className="ml-auto"
                        type="file"
                        accept=".xlsx, .csv"
                        onChange={(e) => setAccessories(e.target.files)}
                      />
                      <button
                        id="uploadButton"
                        className="tracking-widest text-xs ml-5 py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
                        onClick={() =>
                          onSheetUpload(accessories, "accessories")
                        }
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                  <div className="flex mb-4 mt-8">
                    <h1 className="font-bold text-lg ">
                      Other Tables Pricing Details
                    </h1>
                  </div>
                  <div>
                    <div className="flex flex-col md:flex-row items-center w-full justify-between lg:w-4/5">
                      <p className="w-2/5">Pricing Sheet: </p>
                      <input
                        className="ml-auto"
                        type="file"
                        accept=".csv"
                        onChange={(e) => setOtherTables(e.target.files)}
                      />
                      <button
                        id="uploadButton"
                        className="tracking-widest text-xs ml-5 py-3 px-8 hover:bg-black bg-customBlack-light text-white rounded-sm font-bold transform transition-colors"
                        onClick={() => onSheetUpload(otherTables, "components")}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
