// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Imported",
        required: false,
        options: [
          {
            name: "Prestige White(grey)",
            size: 1.01,
            Category: "Prestige White(grey)",
            thumbnail: "assets/chairs/pw.png",
            enableOptions: ["Self Calibrating"],
            productId: "667ac063580af240273458d6 ",
          },
          {
            name: "Desire",
            size: 1.02,
            Category: "Desire",
            thumbnail: "assets/chairs/de.png",
            enableOptions: ["Self Calibrating"],
            productId: "66712e3e09488d8495094e54",
          },
          {
            name: "Venus HB",
            size: 1.03,
            Category: "Venus HB",
            thumbnail: "assets/chairs/vh.png",
            enableOptions: ["Self Calibrating"],
            productId: "66750f7c2d5cdda2c7524bbe",
          },
          {
            name: "Prestige(Black)",
            size: 1.04,
            Category: "Prestige(Black)",
            thumbnail: "assets/chairs/pr.png",
            enableOptions: ["Self Calibrating"],
            productId: "666c3890d48e62609c0a6da4",
          },
          {
            name: "ErgonMesh ( white ) HB",
            size: 1.05,
            Category: "ErgonMesh ( white ) HB",
            thumbnail: "assets/chairs/eh.png",
            enableOptions: ["Self Calibrating"],
            productId: "665f4a9c62c1b890ad4e7053",
          },
          {
            name: "ErgonMesh ( white ) MB",
            size: 1.06,
            Category: "ErgonMesh ( white ) MB",
            thumbnail: "assets/chairs/em.png",
            enableOptions: ["Self Calibrating"],
            productId: "664c2e4650cef10b79fdd20f",
          },
          {
            name: "ErgonMesh ( Black ) HB",
            size: 1.07,
            Category: "ErgonMesh ( Black ) HB",
            thumbnail: "assets/chairs/ebh.png",
            enableOptions: ["Self Calibrating"],
            productId: "665f4a7aed6d0287f500d624",
          },
          {
            name: "ErgonMesh ( Black ) MB",
            size: 1.08,
            Category: "ErgonMesh ( Black ) MB",
            thumbnail: "assets/chairs/ebm.png",
            enableOptions: ["Self Calibrating"],
            productId: "665f4a02cf2001164124f38e",
          },
        ],
      },
      {
        name: "Domestic",
        required: false,
        options: [
          {
            name: "Smart HB",
            thumbnail: "assets/chairs/shb.png",
            size: 1.09,
            Category: "Smart HB",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66968fccd70dfbeefaf1c3b4",
          },
          {
            name: "Vouch",
            thumbnail: "assets/chairs/v.png",
            size: 1.1,
            Category: "Vouch",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],

            productId: "6694fac9c9c7b0d9aa3ab785",
          },
          {
            name: "Leo MB",
            size: 1.11,
            Category: "Leo MB",
            thumbnail: "assets/chairs/leomb.png",
            enableOptions: ["Chrome base", "Nylon Base"],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",

              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],

            productId: "6685418b5c5f3845b2248f55",
          },
          {
            name: "Leo Visitor",
            size: 1.12,
            Category: "Leo Visitor",
            thumbnail: "assets/chairs/leovis.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "668549fa63cae2d6a5c25cf0",
          },
          {
            name: "Divine MB",
            size: 1.13,
            Category: "Devine MB",
            thumbnail: "assets/chairs/devinemb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "667aa40175752ee347e041f8",
          },
          {
            name: "Divine HB",
            size: 1.14,
            Category: "Devine HB",
            thumbnail: "assets/chairs/devinehb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "667aa294b63810fc6c193a12",
          },
          {
            name: "Boston",
            size: 1.15,
            Category: "Boston",
            thumbnail: "assets/chairs/boston.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],

            productId: "6655531d8a7ac17d64ad87a6",
          },
          {
            name: "Webstar MB",
            size: 1.16,
            Category: "Webstar MB",
            thumbnail: "assets/chairs/webstarmb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "667911eb9136ba378db39bd2",
          },
          {
            name: "Webstar HB",
            size: 1.17,
            Category: "Webstar HB",
            thumbnail: "assets/chairs/webstarhb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6679117bc3613b62018eb318",
          },
          {
            name: "Libra MB",
            size: 1.18,
            Category: "Libra MB",
            thumbnail: "assets/chairs/libramb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66796819d80b657307a99bc1",
          },
          {
            name: "Libra HB",
            size: 1.19,
            Category: "Libra HB",
            thumbnail: "assets/chairs/librahb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "667965a02370296b4972af28",
          },
          {
            name: "Smart MB",
            size: 1.2,
            Category: "Smart MB",
            thumbnail: "assets/chairs/smartmb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6655574e35484c4e5b123e19",
          },
          {
            name: "Jupiter MB",
            size: 1.21,
            Category: "Jupiter MB",
            thumbnail: "assets/chairs/jupitermb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",

              "nylon",
              "PU",
            ],
            disableOptions: [
              "Synchro lock",

              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6672bfb320572ea9e41a2469",
          },
          {
            name: "Jupiter HB",
            size: 1.22,
            Category: "Jupiter HB",
            thumbnail: "assets/chairs/jupiterhb.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",

              "nylon",
              "PU",
            ],
            disableOptions: [
              "Synchro lock",

              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6672be4361ed3a35930b7e04",
          },
          {
            name: "Florence",
            size: 1.23,
            Category: "Florence",
            thumbnail: "assets/chairs/fl.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],

            productId: "66712c3046fdb02c4342de0f",
          },
          {
            name: "Talent MB",
            size: 1.24,
            Category: "Talent MB",
            thumbnail: "assets/chairs/tm.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "666998c06a8d840724984a1f",
          },
          {
            name: "Talent HB",
            size: 1.25,
            Category: "Talent HB",
            thumbnail: "assets/chairs/th.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "666998a26efe9b09d22b4fbf",
          },
          {
            name: "Dynamic HB",
            size: 1.26,
            Category: "Dynamic HB",
            thumbnail: "assets/chairs/dh.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "665566940e1abbe5b4f96d17",
          },
          {
            name: "Dynamic MB",
            size: 1.27,
            Category: "Dynamic MB",
            thumbnail: "assets/chairs/dm.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6655653b40249e0cf126234f",
          },
          {
            name: "Crystal HB",
            size: 1.28,
            Category: "Crystal HB",
            thumbnail: "assets/chairs/ch.png",
            enableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
            ],
            disableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "665f496631faf8007d3317dd",
          },
          {
            name: "Oslo",
            size: 1.29,
            Category: "Oslo",
            thumbnail: "assets/chairs/oslo.png",

            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a777ab4511cb75b5d8f766",
          },
          {
            name: "Ocean Tab",
            size: 1.3,
            Category: "Ocean Tab",
            thumbnail: "assets/chairs/oceantab.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a7783378b80d512feb69b1",
          },
          {
            name: "Configure DL",
            size: 1.31,
            Category: "Configure DL",
            thumbnail: "assets/chairs/configurdl.png",
            enableOptions: [
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a77a9f962d09432b3758ab",
          },
          {
            name: "Configure Sl",
            size: 1.32,
            Category: "Configure Sl",
            thumbnail: "assets/chairs/configuresl.png",
            enableOptions: [
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheels",
              "Without Wheels",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "With Wheel",
              "Without Wheel",
            ],
            productId: "66a78a3ef19adb4bfc1fc708",
          },
          {
            name: "@Sense",
            size: 1.33,
            Category: "@Sense",
            thumbnail: "assets/chairs/@sense.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a797c91bf09a691713fb24",
          },
          {
            name: "Colt",
            size: 1.34,
            Category: "Colt",
            thumbnail: "assets/chairs/Colt.png",
            enableOptions: ["Chrome base", "Nylon Base", "nylon", "PU"],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a8876cba89e4c0fce07e4b",
          },
          {
            name: "Colt Visitor",
            size: 1.35,
            Category: "Colt Visitor",
            thumbnail: "assets/chairs/coltvisitor.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66a88bfebc65a28f7e96a54f",
          },

          {
            name: "Lazy",
            size: 1.36,
            Category: "Lazy",
            thumbnail: "assets/chairs/l.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666a8322059e6a8e23a416b",
          },
          {
            name: "Lazy Tab",
            size: 1.37,
            Category: "Lazy Tab",
            thumbnail: "assets/chairs/lt.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666aa662cd56f21497975d5",
          },
          {
            name: "Ocean Visitor",
            size: 1.38,
            Category: "Ocean Visitor",
            thumbnail: "assets/chairs/o.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666c6938a1fdcaa87345076",
          },
          {
            name: "Ocean MB",
            size: 1.39,
            Category: "Ocean MB",
            thumbnail: "assets/chairs/om.png",
            enableOptions: ["Chrome base", "Nylon Base", "nylon", "PU"],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "667d0356bddcc7eb3523fab0",
          },
          {
            name: "Flip Tab",
            size: 1.4,
            Category: "Flip Tab",
            thumbnail: "assets/chairs/ft.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666a534c9adf07421c20613",
          },
          {
            name: "Miami",
            size: 1.41,
            Category: "Miami",
            thumbnail: "assets/chairs/m.png",
            enableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666c11a0f6118de6d549991",
          },
          {
            name: "Kitkat",
            size: 1.42,
            Category: "Kitkat",
            thumbnail: "assets/chairs/k.png",
            enableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666a5aac262d206ba4dfc55",
          },
          {
            name: "Sweden",
            size: 1.43,
            Category: "Sweden",
            thumbnail: "assets/chairs/s.png",
            enableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666c6bf42d7414e00bf0c49",
          },
          {
            name: "Bliss",
            size: 1.44,
            Category: "Bliss",
            thumbnail: "assets/chairs/b.png",
            enableOptions: [
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
            ],
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "6666a483da0af43ab667511a",
          },
          {
            name: "Slam Wood",
            size: 1.45,
            Category: "Slam Wood",
            thumbnail: "assets/chairs/sw.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "665ea3e1cf24974bb6ee1d0a",
          },
          {
            name: "Slam Crest",
            size: 1.46,
            Category: "Slam Crest",
            thumbnail: "assets/chairs/sc.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "665ea2233d372d893c582419",
          },
          {
            name: "Kiwi",
            size: 1.47,
            Category: "Kiwi",
            thumbnail: "assets/chairs/kiwi.png",
            disableOptions: [
              "2D Arm",
              "Cliq",
              "OYO 3D",
              "Sonic pp",
              "Chrome base",
              "Nylon Base",
              "Self Calibrating",
              "Synchro lock",
              "nylon",
              "PU",
              "Black",
              "White",
              "Grey",
              "Blue",
              "Orange",
              "Yellow",
              "Graphite grey",
              "Pure white",
              "Nimbus grey",
              "Super matt black",
              "With Wheel",
              "Without Wheel",
              "Folding",
              "Non Folding",
              "With Wheels",
              "Without Wheels",
            ],
            productId: "66555b389bc32ca5464cc1b7",
          },
        ],
      },
    ],
  },

  {
    name: "Hand Rest",
    multiSelect: false,
    options: [
      {
        name: "Imported",
        required: false,
        options: [
          {
            name: "Hand Rest",
            required: false,
            options: [
              // {
              //   name: "type0",
              //   thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "Hand_rest",
              //       pid: "https://content.helloviewer.io/uploads/8d3872ac-c417-4785-a67e-5014a17e1b0e/30_4_2024/1717060601217/hnadresttyp0.glb",
              //     },
              //   ],
              // },
              // {
              //   name: "type1",
              //   thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "Hand_rest",
              //       pid: "https://content.helloviewer.io/uploads/3da219a5-97c2-4818-82e3-d11d8ed63202/30_4_2024/1717051743539/hnadresttyp1.glb",
              //     },
              //   ],
              // },
              //   {
              //     name: "Scope",
              //     thumbnail: "assets/chairs/thumbnails/Parts/scope.png",
              //     rules: [
              //       {
              //         type: "replacePart",
              //         node: "Hand_rest",
              //         pid: "https://content.helloviewer.io/uploads/17b0a76e-30a2-4b38-bbe3-16d1cb0c392f/29_4_2024/1716990006637/scope.glb",
              //       },
              //     ],
              //   },
              //   {
              //     name: "Io",
              //     thumbnail: "assets/chairs/thumbnails/Parts/Io.png",
              //     rules: [
              //       {
              //         type: "replacePart",
              //         node: "Hand_rest",
              //         pid: "https://content.helloviewer.io/uploads/87e6b1e2-1085-4c53-be5e-e79ac15587ef/29_4_2024/1716989776425/IO.glb",
              //       },
              //     ],
              //   },
              //   {
              //     name: "Pole",
              //     thumbnail: "/assets/components/Perform-D3.png",
              //     rules: [
              //       {
              //         type: "replacePart",
              //         node: "Hand_rest",
              //         pid: "https://content.helloviewer.io/uploads/d1835fa7-98f3-4c42-8829-b9c1fb81bbd3/29_4_2024/1716991428101/Pole-leg.glb",
              //       },
              //     ],
              //   },
              //   {
              //     "name": "Styl",
              //     "thumbnail": "/assets/components/SwitchAngularSharing.png",
              //     "rules": [
              //       {
              //         "type": "replacePart",
              //         "node": "Hand_rest",
              //         "pid": "https://content.helloviewer.io/uploads/09b0d471-0b39-4226-9997-4e410d8d7235/29_4_2024/1716991216441/styl.glb",
              //       },
              //     ]
              //   },
              //   {
              //     "name": "Europa",
              //     "thumbnail": "/assets/components/SwitchAngularSharing.png",
              //     "rules": [
              //       {
              //         "type": "replacePart",
              //         "node": "Hand_rest",
              //         "pid": "https://content.helloviewer.io/uploads/37993e61-1439-4485-8f68-1cf6e298ddd1/29_4_2024/1716988806699/Europa-leg.glb",
              //       },
              //     ]
              //   }
            ],
          },
        ],
      },
      {
        name: "Domestic",
        required: false,
        options: [
          {
            name: "Hand Rest",
            required: false,
            options: [
              {
                name: "2D Arm",
                thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "Hand_rest",
                    pid: "https://content.helloviewer.io/uploads/49b45eb3-858f-45a7-80c8-a8b36a7486f6/4_5_2024/1717514702855/2D-ARM.glb",
                  },
                ],
              },
              {
                name: "Cliq",
                thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "Hand_rest",
                    pid: "https://content.helloviewer.io/uploads/56a588c6-ccaf-4b08-9706-60083f7a79a9/4_5_2024/1717514790814/cliq.glb",
                  },
                ],
              },
              {
                name: "OYO 3D",
                thumbnail: "assets/chairs/thumbnails/Parts/scope.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "Hand_rest",
                    pid: "https://content.helloviewer.io/uploads/caa90968-b19c-40ba-bada-5a24f992fedd/4_5_2024/1717514847316/OYO-3D.glb",
                  },
                ],
              },
              {
                name: "Sonic pp",
                thumbnail: "assets/chairs/thumbnails/Parts/Io.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "Hand_rest",
                    pid: "https://content.helloviewer.io/uploads/da6697fe-f7ba-4c30-b9b0-2028a9c051d9/4_5_2024/1717519946719/SONIC-PP.glb",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  //

  {
    name: "Base",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Imported",
        required: false,
        options: [
          {
            name: "Base",
            required: false,
            options: [
              // {
              //   name: "type0",
              //   thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "base",
              //       pid: "https://content.helloviewer.io/uploads/5fe13f50-188e-490d-a1c8-4a3640383539/30_4_2024/1717059699047/typ0-base.glb",
              //     },
              //   ],
              // },
              // {
              //   name: "type1",
              //   thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "base",
              //       pid: "https://content.helloviewer.io/uploads/9ee7c4c1-3292-4f1a-a436-d149c800363e/30_4_2024/1717051666544/base-typ1.glb",
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "Domestic",
        required: false,
        options: [
          {
            name: "Base",
            required: false,
            options: [
              {
                name: "Chrome base",
                thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "base",
                    pid: "https://content.helloviewer.io/uploads/055d96da-ebb2-4df3-afbb-b0708328c7e6/1_7_2024/1722496010314/chrome-base.glb",
                  },
                ],
              },
              {
                name: "Nylon Base",
                thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "base",
                    pid: "https://content.helloviewer.io/uploads/899fc6d7-79e9-4781-b506-2dbb0f4fe073/10_5_2024/1718021615209/nylon-bae.glb",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      // {
      //   name: "Imported",
      //   required: false,
      //   options: [
      //     {
      //       name: "Tangerine",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Tangerine_F-316.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Acid",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Acid_F-207.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Acorn",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Acorn_F-128.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Amethyst",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Amethyst_F-186.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Apollo",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Apollo_F-131.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Azure",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Azure_F-169.jpg",
      //         },
      //       ],
      //     },

      //     {
      //       name: "Blush",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Blush_F-212.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Brown",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Brown_F-146.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Burnt Orange",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Burnt_Orange_F-149.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Corn Flower",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Corn_Flower_F-164.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Dolphin",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Dolphin_F-161.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Magenta",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Magenta_F-156.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Med Blue",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Med_Blue_F-191.jpg",
      //         },
      //       ],
      //     },

      //     {
      //       name: "Pink",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Pink_F-214.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Purple",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Purple_F-210.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Raspberry",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Raspberry_F-211.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Ruby",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Ruby_F-152.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Salmon",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Salmon_F-145.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Sangria",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Sangria_F-148.jpg",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Shimmer",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/Shimmer_F-190.jpg",
      //         },
      //       ],
      //     },

      //     {
      //       name: "Electric",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/MS_403_Electric.png",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Frengia",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/MS_428_Frengia.png",
      //         },
      //       ],
      //     },
      //     {
      //       name: "Baby Blue",
      //       thumbnail:
      //         "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
      //       rules: [
      //         {
      //           type: "applyMaterial",
      //           node: "Cushion",
      //           textureUrl:
      //             "https://content.helloviewer.io/officemate/textures/fabric/MS_401_Baby_Blue.png",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Domestic",
        required: false,
        options: [
          {
            name: "Wine",
            thumbnail: "assets/textures/Fabric_chair/Wine_f-194.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Wine_f-194.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "assets/textures/Fabric_chair/Waterfall_f-108.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Waterfall_f-108.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "assets/textures/Fabric_chair/Tangerine_F-316.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Tangerine_F-316.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "assets/textures/Fabric_chair/Stone_f-1018.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Stone_f-1018.jpg",
              },
            ],
          },
          {
            name: "Purple",
            thumbnail: "assets/textures/Fabric_chair/Purple_F-210.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Purple_F-210.jpg",
              },
            ],
          },
          {
            name: "Pearl",
            thumbnail: "assets/textures/Fabric_chair/Pearl_F-187.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Pearl_F-187.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "assets/textures/Fabric_chair/Olive_f-1021.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Olive_f-1021.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "assets/textures/Fabric_chair/Greysto_f333.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Greysto_f333.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "assets/textures/Fabric_chair/Granada-f126.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Granada-f126.jpg",
              },
            ],
          },
          {
            name: "Gago Orange",
            thumbnail: "assets/textures/Fabric_chair/Gago_orange_F-181.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl:
                  "assets/textures/Fabric_chair/Gago_orange_F-181.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "assets/textures/Fabric_chair/Flame_f-123.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Flame_f-123.jpg",
              },
            ],
          },
          {
            name: "Electric",
            thumbnail: "assets/textures/Fabric_chair/Electric_f-162.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Electric_f-162.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "assets/textures/Fabric_chair/Dolphin_F-161.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Dolphin_F-161.jpg",
              },
            ],
          },
          {
            name: "Corn Flower",
            thumbnail: "assets/textures/Fabric_chair/Corn_Flower_F-164.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl:
                  "assets/textures/Fabric_chair/Corn_Flower_F-164.jpg",
              },
            ],
          },
          {
            name: "Brown",
            thumbnail: "assets/textures/Fabric_chair/Brown_F-146.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Brown_F-146.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "assets/textures/Fabric_chair/Black_F-137.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Black_F-137.jpg",
              },
            ],
          },
          {
            name: "Azure",
            thumbnail: "assets/textures/Fabric_chair/Azure_F-169.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Azure_F-169.jpg",
              },
            ],
          },
          {
            name: "Amarillo",
            thumbnail: "assets/textures/Fabric_chair/Amarillo_f_112.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Cushion",
                textureUrl: "assets/textures/Fabric_chair/Amarillo_f_112.jpg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Mechanism",
    multiSelect: false,
    optional: false,
    options: [
      // {
      //   name: "Imported",
      //   required: false,

      //   options: [
      //     // {
      //     //   name: "type0",
      //     //   thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
      //     //   rules: [
      //     //     {
      //     //       type: "replacePart",
      //     //       node: "Mechanism",
      //     //       pid: "https://content.helloviewer.io/uploads/d6b74be5-ddce-45fd-a22c-0c19cf24cd4c/30_4_2024/1717059593250/mechanishtyp0.glb",
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: "type1",
      //     //   thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
      //     //   rules: [
      //     //     {
      //     //       type: "replacePart",
      //     //       node: "Mechanism",
      //     //       pid: "https://content.helloviewer.io/uploads/0004de0c-b4b9-49b2-a4ea-e741a7b6554a/30_4_2024/1717051799855/mechnishtyp1.glb",
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      {
        name: "Domestic",
        required: false,

        options: [
          {
            name: "Self Calibrating",
            thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
            rules: [
              {
                type: "replacePart",
                node: "Mechanism",
                pid: "https://content.helloviewer.io/uploads/e0af0baa-ac9d-4296-b604-27daa1023ad2/5_5_2024/1717567791008/Self-Calibrating.glb",
              },
            ],
          },
          {
            name: "Synchro lock",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: "replacePart",
                node: "Mechanism",
                pid: "https://content.helloviewer.io/uploads/7ca0b763-cd9c-48f3-9122-4e396dc1af61/4_5_2024/1717520335895/synchro-lock.glb",
              },
            ],
          },
          {
            name: "Folding",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [],
          },
          {
            name: "Non Folding",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [],
          },
        ],
      },
    ],
  },
  {
    name: "Wheels",
    multiSelect: false,
    optional: false,
    options: [
      // {
      //   name: "Imported",
      //   required: false,

      //   options: [
      //     {
      //       name: "type0",
      //       thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
      //       rules: [
      //         {
      //           type: "replacePart",
      //           node: "wheels",
      //           pid: "https://content.helloviewer.io/uploads/fac6cc14-ded2-4800-9b8e-27ce03a70e09/30_4_2024/1717059792224/wheeltyp0.glb",
      //         },
      //       ],
      //     },
      //     {
      //       name: "type1",
      //       thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
      //       rules: [
      //         {
      //           type: "replacePart",
      //           node: "wheels",
      //           pid: "https://content.helloviewer.io/uploads/09734fad-379a-4a10-b7b4-d59c2cbe8876/30_4_2024/1717051706209/wheelstype1.glb",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Domestic",
        required: false,

        options: [
          {
            name: "Nylon",
            thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
            rules: [
              {
                type: "replacePart",
                node: "wheels",
                pid: "https://content.helloviewer.io/uploads/fac6cc14-ded2-4800-9b8e-27ce03a70e09/30_4_2024/1717059792224/wheeltyp0.glb",
              },
            ],
          },
          {
            name: "PU",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: "replacePart",
                node: "wheels",
                pid: "https://content.helloviewer.io/uploads/09734fad-379a-4a10-b7b4-d59c2cbe8876/30_4_2024/1717051706209/wheelstype1.glb",
              },
            ],
          },
          {
            name: "With Wheel",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: "replacePart",
                node: "rp",
                pid: "https://content.helloviewer.io/uploads/6fadfc6e-aa8b-4cc0-a208-6b40532e4cf1/1_7_2024/1722494543378/Configure-dlwheel.glb",
              },
            ],
          },
          {
            name: "Without Wheel",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: hide,
                node: "rp",
              },
            ],
          },
          {
            name: "With Wheels",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: "replacePart",
                node: "wl",
                pid: "https://content.helloviewer.io/uploads/2b63a117-dcf3-47e0-b268-d9e78fc10c2b/1_7_2024/1722494947230/Configure-slwheel.glb",
              },
            ],
          },
          {
            name: "Without Wheels",
            thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
            rules: [
              {
                type: hide,
                node: "wl",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Plastic Color",
    multiSelect: false,
    optional: false,
    options: [
      // {
      //   name: "Imported",
      //   required: false,

      //   options: [
      //     {
      //       name: "type0",
      //       thumbnail: "assets/chairs/thumbnails/Parts/Eco.png",
      //       rules: [
      //         {
      //           type: "replacePart",
      //           node: "wheels",
      //           pid: "https://content.helloviewer.io/uploads/fac6cc14-ded2-4800-9b8e-27ce03a70e09/30_4_2024/1717059792224/wheeltyp0.glb",
      //         },
      //       ],
      //     },
      //     {
      //       name: "type1",
      //       thumbnail: "assets/chairs/thumbnails/Parts/@work.png",
      //       rules: [
      //         {
      //           type: "replacePart",
      //           node: "wheels",
      //           pid: "https://content.helloviewer.io/uploads/09734fad-379a-4a10-b7b4-d59c2cbe8876/30_4_2024/1717051706209/wheelstype1.glb",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        name: "Domestic",
        required: false,

        options: [
          {
            name: "Black",
            thumbnail: "assets/textures/Plastic/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/Black.jpg",
              },
            ],
          },
          {
            name: "White",
            thumbnail: "assets/textures/Plastic/White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/White.jpg",
              },
            ],
          },
          {
            name: "Grey",
            thumbnail: "assets/textures/Plastic/Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/Grey.jpg",
              },
            ],
          },
          {
            name: "Blue",
            thumbnail: "assets/textures/Plastic/Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/Blue.jpg",
              },
            ],
          },
          {
            name: "Orange",
            thumbnail: "assets/textures/Plastic/Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/Orange.jpg",
              },
            ],
          },
          {
            name: "Yellow",
            thumbnail: "assets/textures/Plastic/Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "Materile",
                textureUrl: "assets/textures/Plastic/Yellow.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Domestic",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
