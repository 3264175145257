import { Console } from "console";
import { UserConfiguration } from "../types/types";

const getDescription = (userConfig: Partial<UserConfiguration>) => {
  const {
    type,
    Legs,
    Length,
    Height,
    Dia,
    noOfSeats,
    Raceway,
    Pedestal,
    Laminate,
    Accessories,
    name
  } = userConfig;
  const Category = userConfig["Category"];
  const wireAccess = userConfig["Wire Access"];
  const Leg = userConfig["Leg"];
  const Length_1 = userConfig["Length-1"];
  const Length_2 = userConfig["Length-2"];
  const size = userConfig["ECO - SIZE OPTIONS"];
  const wireEntry = userConfig["Wire Entry"];
  const mainScreen = userConfig["Main screen"];
  const returnScreen = userConfig["Return screen"];
  const sideModesty = userConfig["Side Modesty"];
  const mainModesty = userConfig["Main Modesty"];
  const powderCoat = userConfig["Powder Coat"];
  const glassColor = userConfig["Glass Color"];
  const fabricColor = userConfig["Fabric Color"];
  const colorsLegColor = userConfig["Color Options"];
  const Depth = userConfig["Depth"];
  const ReturnTableTopLeg = userConfig["Return Table Top Leg"];
  const Return = userConfig["Return"];
  const Back = userConfig["Back"];
  const TableTopOptions = userConfig["Table Top Options"];
  const PlainRacewayOptions = userConfig["Plain Raceway Options"];
  const ReverseWaterfallRacewayOptions =
    userConfig["Reverse Waterfall Raceway Options"];
  const SideRadiusRacewayOptions = userConfig["Side Radius Raceway Options"];
  const Handle = userConfig["Handle"];
  const HandlesColor = userConfig["Handle's Color"];
  const Lock = userConfig["Lock"];
  const StorageFacia = userConfig["Storage Facia"];
  const StorageSide = userConfig["Storage Side"];
  const PedestalFacia = userConfig["Pedestal Facia"];
  const PedestalSide = userConfig["Pedestal Side"];
  const PedestalTop = userConfig["Pedestal Top"];
  const AluminiumPanelOptions = userConfig["Aluminium Panel Options"];
  const FabricPanelAccessories = userConfig["Fabric Panel Accessories"];
  const EndToEndPanelOptions = userConfig["End to End Panel Options"];
  const PrivacyPanels = userConfig["Privacy Panels"];
  const ReturnPanels = userConfig["Return Panels"];
  const KeyboardTrays = userConfig["Keyboard Trays"];
  const CPUStands = userConfig["CPU Stands"];
  const PedestalLegs = userConfig["Pedestal's Legs"];
  const Cushion = userConfig["Cushion"];
  const PlasticColor = userConfig["Plastic Color"];
  const Wheels = userConfig["Wheels"];
  const Mechanism = userConfig["Mechanism"];
  const HandRest = userConfig["Hand Rest"];
  const Base = userConfig["Base"];
  const WireManagementDimensions = userConfig["Wire Management's Dimensions"];
  const sideTable = userConfig["Side Table"]
    ? userConfig["Side Table"]
    : undefined;
  const SharingLegs = userConfig["Sharing Legs"];
  const configArray = [
    Category !== undefined ? `Category : ${Category}` : undefined,
    type !== "SHARING" && type !== "NON SHARING" ? `Name : ${name ?? type}` : undefined,
    type === "SHARING" || type === "NON SHARING"
      ? `Name : ${noOfSeats} Seater - ${type}`
      : undefined,
    Length !== undefined ? `Length : ${Length}W` : undefined,
    Height !== undefined ? `Height : ${Height}D` : undefined,
    Depth !== undefined ? `Depth : ${Depth}mm` : undefined,
    Dia !== undefined ? `Diameter : ${Dia}mm` : undefined,
    Leg !== undefined ? `Leg : ${Leg}` : undefined,
    Legs !== undefined ? `Legs : ${Legs}` : Legs,
    SharingLegs !== undefined ? `Sharing Legs : ${SharingLegs}` : undefined,
    Legs === "Colors" && colorsLegColor
      ? `Leg Color : ${colorsLegColor}`
      : undefined,
    sideTable && `SideTable Dimensions : ${sideTable}`,
    Raceway ? `Raceway : ${Raceway}` : undefined,
    wireAccess ? `Wire Access : ${wireAccess}` : undefined,
    wireEntry ? `Wire Entry : ${wireEntry}` : undefined,

    mainScreen ? `Main Screen : ${mainScreen}` : undefined,
    returnScreen ? `Return Screen : ${returnScreen}` : undefined,
    mainModesty ? `Main Modesty : ${mainModesty}` : undefined,
    sideModesty ? `Side Modesty : ${sideModesty}` : undefined,
    Laminate ? `Laminate : ${Laminate}` : undefined,
    powderCoat ? `Powder Coat : ${powderCoat}` : undefined,
    glassColor ? `Glass Color : ${glassColor}` : undefined,
    fabricColor ? `Fabric Color : ${fabricColor}` : undefined,
    Pedestal ? `Pedestal : ${Pedestal}` : undefined,
    Length_1 ? `Length_1 : ${Length_1}` : undefined,
    Length_2 ? `Length_2 : ${Length_2}` : undefined,
    size ? `size : ${size}` : undefined,
    ReturnTableTopLeg
      ? `Return Table Top Leg : ${ReturnTableTopLeg}`
      : undefined,
    Return ? `Return : ${Return}` : undefined,
    Back ? `Back : ${Back}` : undefined,
    TableTopOptions ? `Table Top Options : ${TableTopOptions}` : undefined,
    PlainRacewayOptions
      ? `Plain Raceway Options : ${PlainRacewayOptions}`
      : undefined,
    ReverseWaterfallRacewayOptions
      ? `Reverse Waterfall Raceway Options : ${ReverseWaterfallRacewayOptions}`
      : undefined,
    SideRadiusRacewayOptions
      ? `Side Radius Raceway Options : ${SideRadiusRacewayOptions}`
      : undefined,
    Handle ? `Handle : ${Handle}` : undefined,
    HandlesColor ? `Handle's Color : ${HandlesColor}` : undefined,
    Lock ? `Lock : ${Lock}` : undefined,
    StorageFacia ? `Storage Facia : ${StorageFacia}` : undefined,
    StorageSide ? `Storage Side : ${StorageSide}` : undefined,
    PedestalFacia ? `Pedestal Facia : ${PedestalFacia}` : undefined,
    PedestalSide ? `Pedestal Side : ${PedestalSide}` : undefined,
    PedestalTop ? `Pedestal Top : ${PedestalTop}` : undefined,
    AluminiumPanelOptions
      ? `Aluminium Panel Options : ${AluminiumPanelOptions}`
      : undefined,
    FabricPanelAccessories
      ? `Fabric Panel Accessories : ${FabricPanelAccessories}`
      : undefined,
    EndToEndPanelOptions
      ? `End to End Panel Options : ${EndToEndPanelOptions}`
      : undefined,
    PrivacyPanels ? `Privacy Panels : ${PrivacyPanels}` : undefined,
    ReturnPanels ? `Return Panels : ${ReturnPanels}` : undefined,
    KeyboardTrays ? `Keyboard Trays : ${KeyboardTrays}` : undefined,
    CPUStands ? `CPU Stands : ${CPUStands}` : undefined,
    PedestalLegs ? `Pedestal's Legs : ${PedestalLegs}` : undefined,
    Cushion ? `Cushion : ${Cushion}` : undefined,
    PlasticColor ? `Plastic Color : ${PlasticColor}` : undefined,
    Wheels ? `Wheels : ${Wheels}` : undefined,
    Mechanism ? `Mechanism : ${Mechanism}` : undefined,
    HandRest ? `Hand Rest : ${HandRest}` : undefined,
    Base ? `Base : ${Base}` : undefined,
    WireManagementDimensions
      ? `Wire Management's Dimensions : ${WireManagementDimensions}`
      : undefined,
    // Accessories && Accessories.length > 0
    //   ? `Accessories : ${Accessories.join(", ")}`
    //   : undefined,
  ];
  console.log(configArray);
  return configArray
    .filter((val) => {
      return val !== undefined;
    })
    .join(", ");
};

export default getDescription;
