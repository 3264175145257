// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const show = "show";
// const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

// const bindPart = "bindPart";
// const unbind = "unbind";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2SNeoSharedNoRet.png",
            productId: "6357c40f46b4084806db88b1",
          },
          {
            name: "Four Seater",
            size: 4,
            thumbnail: "/assets/system/4SNeoSharedNoRet.png",
            productId: "6357c967fb757150b7a740a0",
          },
          {
            name: "Six Seater",
            size: 6,
            thumbnail: "/assets/system/6SNeoSharedNoRet.png",
            productId: "6357c718fb757150b7a73fbc",
          },
        ],
      },
      {
        name: "Non Sharing with only main partition",
        required: false,
        options: [
          {
            name: "One Seater",
            size: 1,
            thumbnail: "/assets/system/1SNeoNonSharedNoRet.png",
            productId: "6358a9063baf6af0fefb7560",
          },
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2SNeoNonSharedNoRet.png",
            productId: "6358a968e7c2b38cc219834f",
          },
          {
            name: "Three Seater",
            size: 3,
            thumbnail: "/assets/system/3SNeoNonSharedNoRet.png",
            productId: "6358aa743691eb40f4838c59",
          },
        ],
      },
      {
        name: "Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2SNeoSharedRet.png",
            productId: "6357def30b3c8bebe094ff30",
          },
          {
            name: "Four Seater",
            size: 4,
            thumbnail: "/assets/system/4SNeoSharedRet.png",
            productId: "6357e8d039fc9478235dfaa4",
          },
          {
            name: "Six Seater",
            size: 6,
            thumbnail: "/assets/system/6SNeoSharedRet.png",
            productId: "6357e96f39fc9478235dfae0",
          },
        ],
      },
      {
        name: "Non Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "One Seater",
            size: 1,
            thumbnail: "/assets/system/1SNeoNonSharedRet.png",
            productId: "6357d4490b3c8bebe094fbc8",
          },
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2SNeoNonSharedRet.png",
            productId: "6357d4a50b3c8bebe094fbd3",
          },
          {
            name: "Three Seater",
            size: 3,
            thumbnail: "/assets/system/3SNeoNonSharedRet.png",
            productId: "6357d8fb8eff3ad618ac46fb",
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "Sharing with only main partition",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with only main partition",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Sharing with main + return partition",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with main + return partition",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["table_top", "leg_wood", "screen_wood"],
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "metal",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with only main partition",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "Non Sharing with main + return partition",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
