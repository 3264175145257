export const testConfigurations = [
  {
    name: "Four Seater Sharing Workstation",
    config: {
      type: "SHARING",
      noOfSeats: 4,
      Length: 1650,
      Height: 800,
      Legs: "Colors",
      Raceway: "Flip Down Tray",
      "Wire Access": "FlipUp",
      "Wire Entry": "Intermediate-Leg Wire Entry",
      "FlipUp Position": "Center",
      "Main screen": "30MM AL Screen Edge-Edge-350H",
      Accessories: ["Nameboard"],
      Pedestal: "3 Equal Drawers with Single Lock",
    },
  },
  {
    name: "Six Seater Meeting Table",
    config: {
      type: "Meeting Table",
      noOfSeats: 6,
      Length: 2100,
      Height: 1050,
      Legs: "Colors",
    },
  },
];
