import axios from "axios";
import { getCostApiConfiguration } from "./serializeConfigData";

const pricingUrl = process.env.REACT_APP_HELLOAR_PRICING;
export const uploadSheet = async (data: FormData, type: string) => {
  const url = pricingUrl + `upload/${type}_pricing`;
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : undefined;

  const res = await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${user.access_token}`,
    },
  });
  return res;
};

export const getPricing = async (config: any) => {
  if (config.type === "SHARING" || config.type === "NON SHARING") {
    const serializedData = getCostApiConfiguration(config);
    const res = await axios.post(
      pricingUrl + "price/",
      JSON.stringify(serializedData),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data.totalPrice;
  } else {
    const currentTableArray = [
      config.type
        ? config.type.split(" ").join("")
        : config.type?.split(" ").join(""),
      config.Length && config.Height
        ? `${config.Length}Wx${config.Height}D`
        : undefined,
      config.type === "Cabin Table" ? "MainTable" : undefined,
      config.Dia ? `${config.Dia}Dia` : undefined,
      config.Legs.split(" ").join(""),
    ];

    const currentTable = currentTableArray
      .filter((option) => option !== undefined)
      .join("_");
    const sideTable = config["Side Table"]
      ? "CabinTable_" +
        config["Side Table"]?.split(" x ").join("Wx") +
        "D_SideTable" +
        "_" +
        config.Legs.split(" ").join("")
      : undefined;
    const pricingReqBody =
      sideTable === undefined
        ? [
            {
              name: currentTable,
              quantity: 1,
            },
          ]
        : [
            {
              name: currentTable,
              quantity: 1,
            },
            {
              name: sideTable,
              quantity: 1,
            },
          ];

    const res = await axios.post(
      pricingUrl + "new/price/",
      JSON.stringify(pricingReqBody),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.data
      .map((component: any) => component.price)
      .reduce((acc: number, curr: number) => acc + curr);
  }
};
