import { features } from "process";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "4 Seater",
            Category: "Meeting Tables",
            size: 4,
            Depth: 1800,
            Height: 900,
            thumbnail: "assets/system/thumbnails/meeting/4.png",
            productId: "664f063a9cb5c9d4cb118d4e",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            enableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "6 Seater",
            size: 6,
            Category: "Meeting Tables",
            Depth: 1800,
            Height: 900,
            thumbnail: "assets/system/thumbnails/meeting/6.png",
            productId: "66521e3116d83b3e289b3c97",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            enableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
            disableOptions: ["Flip Up"],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "8 Seater",
            size: 8,
            Category: "Meeting Tables",
            Length: 2400,
            Height: 1200,
            thumbnail: "assets/system/thumbnails/meeting/8.png",
            productId: "665220f05d2b44bc65588941",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            disableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "10 Seater",
            size: 10,
            Category: "Meeting Tables",
            Length: 2400,
            Height: 1200,
            thumbnail: "assets/system/thumbnails/meeting/10.png",
            productId: "665220f05d2b44bc65588941",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            disableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "12 Seater",
            size: 12,
            Category: "Meeting Tables",
            Length: 2400,
            Height: 1200,
            thumbnail: "assets/system/thumbnails/meeting/12.png",
            productId: "665223f374d489b5a574992b",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            disableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        Category: "Meeting Tables",
        options: [
          {
            name: "14 Seater",
            size: 14,
            Length: 2400,
            Height: 1200,
            thumbnail: "assets/system/thumbnails/meeting/12.png",
            productId: "665223f374d489b5a574992b ",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            disableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        Category: "Meeting Tables",
        options: [
          {
            name: "16 Seater",
            size: 16,
            Length: 2400,
            Height: 1200,
            thumbnail: "assets/system/thumbnails/meeting/16.png",
            productId: "665db41d9956eea78cda1a2f ",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
            disableFeatures: [
              "Plain Raceway Options",
              "Reverse Waterfall Raceway Options",
              "Side Radius Raceway Options",
            ],
            disableOptions: [
              "Flip Up With Vertebrae",
              "Flip Up With Vertical Raiser",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "1800 mm",
                value: 1800,
                enableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                disableOptions: ["Flip Up"],
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/8b0bc3f8-650b-4417-a06c-d9a7782c856f/11_6_2024/1720684421907/4Meeting-table.glb",
                  },
                  {
                    type: show,
                    node: ["main"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU_vertibrae",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "1950 mm",
                value: 1950,
                enableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                disableOptions: ["Flip Up"],
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/8b0bc3f8-650b-4417-a06c-d9a7782c856f/11_6_2024/1720684421907/4Meeting-table.glb",
                  },
                  {
                    type: show,
                    node: ["main"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU_vertibrae",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "2100 mm",
                value: 2100,
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                enableOptions: ["Flip Up"],
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/2dfd494a-c9ca-4a64-8b2c-6d1d9c7404aa/11_6_2024/1720684546559/8PAX-MEETING-TABLE.glb",
                  },
                  {
                    type: show,
                    node: ["main"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "FlipU_vertibrae",
                      "mid_leg_europa_cover",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "2250 mm",
                value: 2250,
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                enableOptions: ["Flip Up"],
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/2dfd494a-c9ca-4a64-8b2c-6d1d9c7404aa/11_6_2024/1720684546559/8PAX-MEETING-TABLE.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "FlipU_raser_radious",
                      "FlipU_radious",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_vertibrae",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "2400 mm",
                value: 2400,
              },
              {
                name: "2600 mm",
                value: 2600,
              },
              {
                name: "2800 mm",
                value: 2800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "3000 mm",
                value: 3000,
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/2dfd494a-c9ca-4a64-8b2c-6d1d9c7404aa/11_6_2024/1720684546559/8PAX-MEETING-TABLE.glb",
                  },
                  {
                    type: show,
                    node: ["main"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU_vertibrae",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
              {
                name: "3300 mm",
                value: 3300,
                rules: [
                  {
                    type: replacePart,
                    node: ["main"],
                    pid: "https://content.helloviewer.io/uploads/d77ed568-0f53-40a4-be38-ab4b9a2d4ccc/11_6_2024/1720684617393/10PAX-MEETING-TABLE.glb",
                  },
                  {
                    type: show,
                    node: ["main"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",

                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU_vertibrae",
                      "FlipU_raser",
                      "mid_leg_cover",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                ],
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "3600 mm",
                value: 3600,
              },
              {
                name: "3750 mm",
                value: 3750,
              },
              {
                name: "3900 mm",
                value: 3900,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "4200 mm",
                value: 4200,
              },
              {
                name: "4500 mm",
                value: 4500,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "4800 mm",
                value: 4800,
              },
              {
                name: "5200 mm",
                value: 5200,
              },
              {
                name: "5600 mm",
                value: 5600,
              },
              {
                name: "6000 mm",
                value: 6000,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
            ],
          },
          {
            name: "Height",
            required: false,
            dropDown: true,
            options: [
              {
                name: "750 mm",
                value: 750,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableFeatures: ["Flip Up With Vertebrae"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableFeatures: ["Flip Up With Vertebrae"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        ////
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   disableOptions: ["Flip Up"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   disableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   disableOptions: ["Flip Up"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   disableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   disableOptions: ["Flip Up"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   disableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   disableOptions: ["Flip Up"],
              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   disableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Leg",
            required: false,
            options: [
              {
                name: "Eco",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/747959d2-3309-4093-8191-bcda3d319529/2_6_2024/1719925981676/ECO-LEFT-LEG.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/4b94e22d-ebef-4575-9026-4cc934a973b7/2_6_2024/1719925936530/ECO-RIGHT-LEG.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: "show",
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Prelam",
              //   enableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   disableOptions: ["Flip Up"],

              //   thumbnail: "assets/system/thumbnails/Parts/Eco.png",
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/aa1ec152-c13f-4915-8379-1d923a89c4a2/11_6_2024/1720683923314/gableleft.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/6637632a-724a-49d4-aa3c-450ce9d2b0d7/11_6_2024/1720683898454/gableright.glb",
              //     },

              //     {
              //       type: hide,
              //       node: [
              //         "IOconnecter",
              //         "Styl_connector",
              //         "connecter_Europa",
              //         "mid_leg_europa",
              //         "mid_leg",
              //         "connecter",
              //         "mid_leg_cover",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["gable_modesty", "midleg_gable"],
              //     },
              //   ],
              // },
              {
                name: "@Work",
                thumbnail: "assets/system/thumbnails/Parts/@work.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/612db956-f761-4a06-935d-5f744cd98c43/25_4_2024/1716653668691/left-work.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/7125f139-073f-4b68-9c98-a10ab3b54f84/25_4_2024/1716653435969/right--work.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Scope",
                thumbnail: "assets/system/thumbnails/Parts/scope.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/d99251e9-ba91-481c-bcb5-fb4409772252/25_4_2024/1716652625543/Scope-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/a3f00929-d5ba-4ae9-8ff1-c9b64661ffd3/25_4_2024/1716652469240/Scope--right-leg.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "IOconnecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["connecter", "mid_leg"],
                  },
                ],
              },
              {
                name: "Io",
                thumbnail: "assets/system/thumbnails/Parts/Io.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "Styl_connector",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["IOconnecter", "mid_leg"],
                  },
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/463650bc-4a24-4313-bda8-117534832885/24_4_2024/1716553885998/IO-left-leg.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/580f1b55-9907-42a1-8590-b3ff6a2e6fc1/24_4_2024/1716553857972/IO-right-leg.glb",
                  },
                ],
              },
              {
                name: "Styl",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableOptions: [
                  "Flip Up With Vertebrae",
                  "Flip Up With Vertical Raiser",
                ],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "https://content.helloviewer.io/uploads/4b4ea300-766b-45ac-8d41-f5cef64912c0/2_6_2024/1719925794465/styl-legleft.glb",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "https://content.helloviewer.io/uploads/654f1668-75be-401e-aae8-961f52d818cf/2_6_2024/1719925714009/styl-legright.glb",
                  },
                  {
                    type: "hide",
                    node: [
                      "connecter",
                      "IOconnecter",
                      "connecter_Europa",
                      "mid_leg_europa",
                      "gable_modesty",
                      "midleg_gable",
                    ],
                  },
                  {
                    type: show,
                    node: ["Styl_connector", "mid_leg"],
                  },
                ],
              },
              // {
              //   name: "Europa",
              //   thumbnail: "/assets/components/SwitchAngularSharing.png",
              //   disableOptions: [
              //     "Flip Up With Vertebrae",
              //     "Flip Up With Vertical Raiser",
              //   ],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "https://content.helloviewer.io/uploads/687eb96d-8d6c-4cb6-bfe8-f95decd17e75/2_6_2024/1719925617153/Europa-left.glb",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "https://content.helloviewer.io/uploads/8cc5110f-043a-4642-9e32-f52beff69d95/2_6_2024/1719925535485/Europa-right.glb",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "IOconnecter",
              //         "Styl_connector",
              //         "gable_modesty",
              //         "mid_leg",
              //         "midleg_gable",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["connecter_Europa", "mid_leg_europa"],
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    multiSelect: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/meeting/4.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/meeting/4.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      ,
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/meeting/4.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      ,
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Table Top Options",
            required: false,

            options: [
              {
                name: "Plain",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Reverse Waterfall Raceway Options",
                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Plain Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Reverse waterfall",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",

                  "Side Radius Raceway Options",
                ],
                enableFeatures: ["Reverse Waterfall Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_reverse"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_radious", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",

                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
              {
                name: "Side radius",
                thumbnail: "assets/system/thumbnails/Parts/Eco.png",
                disableFeatures: [
                  "Plain Raceway Options",
                  "Reverse Waterfall Raceway Options",
                ],
                enableFeatures: ["Side Radius Raceway Options"],
                rules: [
                  {
                    type: show,
                    node: ["Table_top_radious"],
                  },
                  {
                    type: hide,
                    node: ["Table_top_reverse", "Table_top"],
                  },
                  {
                    type: hide,
                    node: [
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "FlipU",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                      "FlipU",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ////
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Plain Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Reverse Waterfall Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_reverse", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_reverse", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
          {
            name: "Side Radius Raceway Options",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "assets/components/none.jpg",
                rules: [
                  {
                    type: hide,
                    node: [
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "FlipU",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["Table_top_radious", "mid_leg"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg", "mid_leg_cover"],
                  },
                ],
              },
              {
                name: "Flip Up",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    node: ["FlipU_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertical Raiser",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_raser_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
              {
                name: "Flip Up With Vertebrae",
                thumbnail: "assets/system/thumbnails/Parts/flipup.png",
                rules: [
                  {
                    type: hide,
                    node: [
                      "FlipU",
                      "Table_top_radious",
                      "Table_top_reverse",
                      "FlipU_radious",
                      "FlipU_raser_radious",
                      "FlipU_vertibrae_radious",
                      "FlipU_raser",
                      "FlipU_vertibrae",
                      "FlipU_reverse",
                      "FlipU_raser_reverse",
                      "FlipU_vertibrae_reverse",
                      "Table_top",
                      "mid_leg_cover",
                      "mid_leg_europa_cover",
                      "mid_leg",
                      "mid_leg_europa",
                    ],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Prelam"] }],
                    node: ["FlipU_vertibrae_radious", "mid_leg_cover"],
                  },
                  {
                    type: hide,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_cover"],
                  },
                  {
                    type: show,
                    condition: [{ feature: "Leg", values: ["Europa"] }],
                    node: ["mid_leg_europa_cover"],
                  },
                  {
                    type: hide,
                    condition: [
                      {
                        feature: "Leg",
                        values: ["Prelam"],
                      },
                    ],
                    node: ["mid_leg_cover", "mid_leg"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Prelam Leg Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "4 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood2/thumbnail/Mangfall_Beech.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Mangfall_Beech.png",
              },
            ],
          },
          {
            name: "Acacia",
            thumbnail: "/assets/textures/Wood2/thumbnail/Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Acacia.png",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood2/thumbnail/Urban_Teak.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Urban_Teak.png",
              },
            ],
          },
          {
            name: "SILVER GREY DIFFUSE",
            thumbnail: "/assets/textures/Wood2/thumbnail/SILVER_GREY.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/SILVER_GREY.png",
              },
            ],
          },
          {
            name: "Off White diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Off_White.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Off_White.png",
              },
            ],
          },
          {
            name: "Graphite Grey diffuse",
            thumbnail: "/assets/textures/Wood2/thumbnail/Graphite_Grey.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Graphite_Grey.png",
              },
            ],
          },
          {
            name: "Cairo Walnut BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Cairo_Walnut.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Cairo_Walnut.png",
              },
            ],
          },
          {
            name: "Maldau Acacia BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Maldau_Acacia.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Maldau_Acacia.png",
              },
            ],
          },
          {
            name: "Fusion Maple",
            thumbnail: "/assets/textures/Wood2/thumbnail/Fusion_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Fusion_Maple.png",
              },
            ],
          },
          {
            name: "Thansau Maple BaseColor",
            thumbnail: "/assets/textures/Wood2/thumbnail/Thansau_Maple.png",
            rules: [
              {
                type: "applyMaterial",
                node: "Wood1",
                textureUrl: "/assets/textures/Wood2/2k/Thansau_Maple.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "4 Seater",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "6 Seater",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "8 Seater",
        required: false,

        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "10 Seater",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "12 Seater",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "14 Seater",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "16 Seater",
        required: false,
        options: [
          {
            name: "Graphite grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Graphite_grey.jpg",
              },
            ],
          },
          {
            name: "Pure white",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Pure_white.jpg",
              },
            ],
          },
          {
            name: "Nimbus grey",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Nimbus_grey.jpg",
              },
            ],
          },
          {
            name: "Super matt black",
            thumbnail:
              "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
            rules: [
              {
                type: "applyMaterial",
                node: "Legs",
                textureUrl:
                  "https://content.helloviewer.io/officemate/textures/Metal/Super_matt_black.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;