
import axios from 'axios';

const baseUrl = process.env.REACT_APP_HELLOAR_API;

const getAccessToken = () => {
    return window.localStorage.getItem('accessToken');
  };

const getAuthHeader = async () => {
  const auth = getAccessToken();
  return auth;
};


export const uploadContentNew = async (fileName, fileType, file, applicationId) => {
  const authToken = await getAuthHeader();
  const configGetS3Url = {
    url: `${baseUrl}signed_file`,
    params: {
      file_name: fileName,
      file_type: fileType,
      ...(applicationId && { application_id: applicationId })
    },
    method: 'get',
    headers: { Authorization: authToken }
  };
  try {
    const response = await axios.request(configGetS3Url);
    const formData = new FormData();
    let key;
    console.log(response.data.data, "THUMBNAIL YO")
    for (key in response.data.data.fields) {
      formData.append(key, response.data.data.fields[key]);
    }
    formData.append('file', file);
    const configUploadToS3 = {
      url: response.data.data.url,
      data: formData,
      method: 'post'
    };
    await axios.request(configUploadToS3);
    return response.data.uploaded_url;
  } catch (err) {
    console.log(err, "THUMBNAIL ERR")
    // throw new Error('Error while uploading content' + JSON.stringify(err));
  }
};

export const fetchProduct = async (productId, embedApplication = false) => {
    try {
      const authHeader = await getAuthHeader();
  
      const embeddedQuery = { application: 1 };
      const config = {
        url: `${baseUrl}application_products/${productId}`,
        method: 'get',
        params: { ...(embedApplication && { embedded: embeddedQuery }) },
        headers: { Authorization: authHeader }
      };
      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      if (err.response.status === 404) {
        const errMsg = 'This product does not exist on the server';
        throw errMsg;
      } else {
        throw err.response ? err.response : "Can't connect to server :(";
      }
    }
  };
  